import { observer } from 'mobx-react-lite'
import React from 'react'
export const EconomicContributionSummerystatus = observer(() => {


  return (
    <div className=" ">
      
      
        <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 0 }}>


 <div  className='flex-1 text-greyDark' style={{borderTop: '1px solid lightgray', fontSize: 10 ,fontWeight:500,marginTop: 5 ,minHeight:50 }}>
<br/>Source: National Bureau of Statistics. 2022. Nigerian Gross Domestic Product Report (Q2 2022). Available   <a class="custom-link" href="https://nigerianstat.gov.ng/elibrary" target="_blank" > here</a>  [Accessed July 2023].

 
      
</div>
 </div>
 
 
      
 
      </div>

      


  )

})
