import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { Map } from '../chartContainers/Map'
import { DensityChart } from '../smallCharts/DensityChart'

export const RoadDensityViz = observer(() => {
  const {
    data: { roadDensity },
  } = useMst()

  return (
    <div className="" style={{ height: '90vh', paddingTop: 20}}>
    <ChartContent
      isMapChart={true}
      chart={
        <Map
          dataset={roadDensity}
          isInteractive={false}
          borderColor="#AB809E"
          MapTileChart={DensityChart}
        />
      }
    />
    </div>
  )
})
