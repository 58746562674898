import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dataset } from '../dataset/dataset';
import { ellipsisTextLineStyle } from '../lib/styleUtils';
import {
  GEOGRAPHY_AND_CLIMATE,
  ECONOMY,
  DEMOGRAPHICS,
  PEOPLE,
  PERCEPTIONS_AND_ATTITUDES,
} from '../lib/constants.js';

import { ReactComponent as WobblePlum } from '../assets/wobbles/plum.colors.svg';
import { ReactComponent as WobblePumpkin } from '../assets/wobbles/pumpkin.colors.svg';
import { ReactComponent as WobblePea } from '../assets/wobbles/pea.colors.svg';
import { ReactComponent as WobblePetroleum } from '../assets/wobbles/petroleum.colors.svg';
import { ReactComponent as WobbleBrown } from '../assets/wobbles/brown.colors.svg';
import Header from './Header';
import { Footer } from './Footer';

import geographyPhoto from '../assets/wobbles/01.png';
import economyPhoto from '../assets/wobbles/02.png';
import demographicsPhoto from '../assets/wobbles/03.png';
import lifePhoto from '../assets/wobbles/04.png';
import perceptionsPhoto from '../assets/wobbles/05.png';

const WOBBLE_HEIGHT = 90;

export function Index() {
  const [hoveredSection, setHoveredItemSection] = useState(null);
  const navigate = useNavigate();

  const handleNavigation = (url) => {
    navigate(`/${url}`);
  };

  return (
    <>
      <div className="flex flex-col h-screen bg-whiteSmoke">
        <Header />

        <div className="flex flex-col flex-grow items-center justify-center p-6">
          <div className="flex md:max-w-192 justify-center items-center flex-grow" style={{ marginTop: 0 }}>
            {dataset.map((section) => {
              const isHovered = section.id === hoveredSection?.id;
              const height = isHovered ? WOBBLE_HEIGHT : WOBBLE_HEIGHT * 0.8;

              return (
                <div
                  key={section.id}
                  className="group flex flex-col flex-1 items-center cursor-pointer max-h-96 transform transition-transform duration-500 ease-in-out"
                  onMouseOver={() => setHoveredItemSection(section)}
                  onMouseOut={() => setHoveredItemSection(null)}
                  onClick={() => handleNavigation(section.url)}
                  tabIndex="0" // Make the div focusable
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleNavigation(section.url);
                    }
                  }}
                  aria-label={`Go to ${section.name} section`} // For screen readers
                  style={{ transform: isHovered ? 'scale(1.1)' : 'scale(1)' }}
                >
                  <div className="relative w-full flex justify-center h-0" style={{ paddingBottom: '100%' }}>
                    {{
                      [GEOGRAPHY_AND_CLIMATE]: (
                        <>
                          <WobblePlum
                            className="transition-maxHeight duration-100 wobble"
                            style={{ height, maxHeight: height, marginTop: 30 }}
                          />
                          <div className="absolute" style={{ top: '20%', left: '20%' }}>
                            <img
                              className="transition-all duration-200"
                              src={geographyPhoto}
                              alt="category photo"
                              style={{ transform: `scale(${isHovered ? 1 : 0})` }}
                            />
                          </div>
                        </>
                      ),
                      [ECONOMY]: (
                        <>
                          <WobblePumpkin
                            className="transition-maxHeight duration-100 wobble"
                            style={{ height, maxHeight: height, marginTop: 0 }}
                          />
                          <div className="absolute" style={{ top: '20%', left: '20%' }}>
                            <img
                              className="transition-all duration-200"
                              src={economyPhoto}
                              alt="category photo"
                              style={{ transform: `scale(${isHovered ? 1 : 0})` }}
                            />
                          </div>
                        </>
                      ),
                      [DEMOGRAPHICS]: (
                        <>
                          <WobblePea
                            className="transition-maxHeight duration-100 wobble"
                            style={{ height, maxHeight: height, marginTop: 50 }}
                          />
                          <div className="absolute" style={{ top: '20%', left: '20%' }}>
                            <img
                              className="transition-all duration-200"
                              src={demographicsPhoto}
                              alt="category photo"
                              style={{ transform: `scale(${isHovered ? 1 : 0})` }}
                            />
                          </div>
                        </>
                      ),
                      [PEOPLE]: (
                        <>
                          <WobblePetroleum
                            className="transition-maxHeight duration-100 wobble"
                            style={{ height, maxHeight: height, marginTop: 20 }}
                          />
                          <div className="absolute" style={{ top: '20%', left: '20%' }}>
                            <img
                              className="transition-all duration-200"
                              src={lifePhoto}
                              alt="category photo"
                              style={{ transform: `scale(${isHovered ? 1 : 0})` }}
                            />
                          </div>
                        </>
                      ),
                      [PERCEPTIONS_AND_ATTITUDES]: (
                        <>
                          <WobbleBrown
                            className="transition-maxHeight duration-100 wobble"
                            style={{ height, maxHeight: height, marginTop: 50 }}
                          />
                          <div className="absolute" style={{ top: '20%', left: '20%' }}>
                            <img
                              className="transition-all duration-200"
                              src={perceptionsPhoto}
                              alt="category photo"
                              style={{ transform: `scale(${isHovered ? 1 : 0})` }}
                            />
                          </div>
                        </>
                      ),
                    }[section.id]}
                  </div>
                  <div className="flex flex-col justify-end small-screen-spacing">
                    <div className={`font-bold text-lg text-${section.colorClass}Dark text-center pt-5 pb-4 section-name`}style={{ minHeight: '8rem' }}>
                      {section.name}
                    </div>
                    <div className="text-center" style={{ ...ellipsisTextLineStyle(5) }}>
                      {section.description}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="absolute bottom-0 w-full text-center" style={{ fontSize: 16 }}></div>
      </div>

      <div className="w-full">
        <Footer />
      </div>
    </>
  );
}
