import React, { useState } from 'react'
import { downloadElementToImage } from '../utils/utils';

import { SquareTile } from '../components/chartContainers/SquareTile'
import { GdpChart } from '../components/smallCharts/GdpChart'
// import DownloadIcon from '@mui/icons-material/Download';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';

import { MapTile } from '../components/chartContainers/MapTile'
import { PieChart } from '../components/smallCharts/PieChart'
import { DAILY_POWER_COLOR_SCALE } from '../lib/constants'

import { MAP_TILE_SIZE_DESKTOP } from '../lib/mapConstants'
const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const DailyPowerLegend = () => {
    const [isDownloading, setIsDownloading] = useState(false);

    const mockDatum = {
        stateCode: 'FC',
        pieData: [
            { name: "lessThan3Hr", value: 15 },
            { name: "from3To7Hr", value: 57 },
            { name: "moreThan7Hr", value: 23 },
            // { name: "notGrid", value: 5 }
        ]
    }
    const handleCaptureDownoadClick = () => {
        const sectionComponent = document.getElementById("subsection");
        const snapshotElem = sectionComponent.getElementsByClassName("map-component")?.[0];
        if (snapshotElem) {
          setIsDownloading(true);
          setTimeout(async () => {
            await downloadElementToImage(snapshotElem,"DailyPower.png");
            setIsDownloading(false);
          }, 0)
        }
      }
    return (
        <div className="ml-10 flex flex-col gap-y-12">
            <div className="font-bold">How to read it</div>
            <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
                <div className="absolute">
                    <MapTile
                        size={MAP_TILE_SIZE_DESKTOP}
                        borderColor="transparent"
                        MapTileChart={() => (
                            <>
                                <div className="absolute text-greyDark top-1 left-1 text-xs">LA</div>
                                <svg>
                                    <circle
                                        r={MAP_TILE_SIZE_DESKTOP / 2 - 5}
                                        fill="transparent"
                                        stroke="#309992"
                                        strokeWidth={1}
                                        transform={`translate(${MAP_TILE_SIZE_DESKTOP / 2}, ${MAP_TILE_SIZE_DESKTOP / 2
                                            })`}
                                    />
                                </svg>
                            </>
                        )}
                    />
                    <div
                        className="absolute top-0 text-sm text-greyDark whitespace-nowrap font-medium"
                        style={{ left: 90 }}
                    >
                        State code
                    </div>
                </div>
            </div>

            <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
                <div
                    className="absolute"
                    style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
                >
                    <MapTile
                        borderColor="transparent"
                        size={MAP_TILE_SIZE_DESKTOP}
                        datum={mockDatum}
                        chartFill="#309992"
                        MapTileChart={PieChart}
                    />
                </div>
                <div
                    className="absolute text-sm text-greyDark"
                    style={{ left: MAP_TILE_SIZE_DESKTOP + 5 }}
                >
                    Daily average hours of power cuts experienced
                </div>
            </div>
            <div className="flex flex-col gap-y-1">
                {DAILY_POWER_COLOR_SCALE.domain()
                    .map((label) => (
                        <div className="legend-element flex justify-between" key={label}>
                            <div className="flex gap-x-1">
                                <div
                                    className="square w-4 h-4"
                                    style={{ background: DAILY_POWER_COLOR_SCALE(label), border: '1px solid #2D9992' }}
                                />
                                <div className="label text-sm">{label}</div>
                            </div>
                        </div>
                    ))}
            </div>
            <div style={{ display: 'flex',  alignItems: 'left',marginLeft:-10 }}>
            <button type="button" onClick={handleCaptureDownoadClick} style={{ color: 'rgb(9, 126, 118)' }}disabled={isDownloading} className="flex items-center">
          {isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
          {!isDownloading && (
            <DownloadIcon
              className="ml-2"
              style={{  width: '26px', height: '26px', stroke: 'rgb(9, 126, 118)', strokeWidth: '2' }} 
            />
          )}
        </button>

        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
        </div>
    )
}
