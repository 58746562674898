import React, { useCallback, useRef, useState } from 'react';
import { useEffect } from 'react';
import { ChartContent } from './ChartContent';

export const PopulationSpatialViz = () => {
  const images = {
    1975: 'Population_1975',
    2000: 'Population_2000',
    2020: 'Population_2020',
  };
  const [populationSelectedYear, setPopulationSelectedYear] = useState(1975);

  const isPlay = useRef(true);
  const rollSlide = useCallback(() => {
    let imageKeys = Object.keys(images);
    let pos = imageKeys.findIndex((el) => el == populationSelectedYear.toString());
    if (pos === -1 || pos === imageKeys.length - 1) {
      setPopulationSelectedYear(+imageKeys[0]);
    } else {
      setPopulationSelectedYear(+imageKeys[pos + 1]);
    }
  }, [populationSelectedYear]);

  useEffect(() => {
    if (isPlay.current) {
      const changeSlide = setInterval(rollSlide, 1200);
      return () => clearInterval(changeSlide);
    }
  }, [populationSelectedYear]);

  return (
    <div style={{ paddingTop: 30 }}>
      <ChartContent
        isMapChart={true}
        chart={
          <div className="flex items-center justify-center h-full w-full">
            <img
              src={`spatial/${images[populationSelectedYear]}.jpg`}
              alt="population growth raster"
              style={{ 
                maxWidth: '100%', 
                height: 'auto', 
                objectFit: 'contain',
                border: '1px solid black' 
              }}
            />
          </div>
        }
      />
    </div>
  );
};
