import { observer } from 'mobx-react-lite'
import React,{useState}from 'react'
//import './App.css'


 export function RoadDensitySummerystatus  ()  {
  


  return (
    <div className=" ">
      <div style={{ borderTop: '1px solid lightgray',marginLeft:-10 ,marginBottom:-2}}>   </div> 
      
        <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 0 }}>

 <table className=' border-b    flex-1 ' style={{  marginRight: 0,maxWidth:3000 ,minHeight:40 }}>
 <tr >
  <th ></th>
 <th></th>
 <th></th>
 <th></th>
 </tr>

 
 <tr>
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1">State with the... </tr>
 <tr style={{ fontSize: 12 ,minWidth:400,fontWeight: 500,padding:3}}> </tr>
 </td>
 


 
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> longest road network (km) </tr>
 <tr class="custom-Geography2"> Kano 9,592 </tr>
 </td>
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> shortest road network (km)  </tr>
 <tr class="custom-Geography2"> Bayelsa 1,176</tr>
 </td>
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> highest road density  </tr>
 <tr class="custom-Geography2"> Lagos 146 km/100 km<sup>2</sup> of land area</tr>
 </td>


 <td style={{ minWidth:0 }}>
 <tr style={{ fontSize: 11,textTransform: 'uppercase' ,fontWeight: 900,padding:3 ,color: '#966084'}}> lowest road density</tr>
 <tr class="custom-Geography2"> Taraba 6 km/100 km<sup>2</sup> of land area</tr>
 </td>
 
 </tr>
 
 </table>



<div className='flex-1 text-greyDark' style={{ fontSize: 10 ,minHeight:44,fontWeight:500,marginTop: 3  }}>
  Source: Open street map OSM. 2020. OpenStreetMap data for Nigeria. Available <a class="custom-link" href="https://download.geofabrik.de/africa/nigeria.html" target="_blank">here</a>
</div>


 </div>
 
 
      
 
      </div>

      


  )
}