import React, { useState } from 'react'
import { VerticalIndexMenu4 } from './VerticalIndexMenu4'
import { useNavigate } from 'react-router-dom'
import { dataset } from '../dataset/dataset'
import { ellipsisTextLineStyle } from '../lib/styleUtils'
import {
  GEOGRAPHY_AND_CLIMATE,
  ECONOMY,
  DEMOGRAPHICS,
  PEOPLE,
  PERCEPTIONS_AND_ATTITUDES,
} from '../lib/constants.js'


import { ReactComponent as Logo } from '..//assets/home_icon.svg'
import { ReactComponent as WobblePlum } from '../assets/wobbles/plum.colors.svg'
import { ReactComponent as WobblePumpkin } from '../assets/wobbles/pumpkin.colors.svg'
import { ReactComponent as WobblePea } from '../assets/wobbles/pea.colors.svg'
import { ReactComponent as WobblePetroleum } from '../assets/wobbles/petroleum.colors.svg'
import { ReactComponent as WobbleBrown } from '../assets/wobbles/brown.colors.svg'


import { ReactComponent as Logo2 } from '../assets/UNDP_logo.svg'

import geographyPhoto from '../assets/wobbles/01.png'
import economyPhoto from '../assets/wobbles/02.png'
import demographicsPhoto from '../assets/wobbles/03.png'
import lifePhoto from '../assets/wobbles/04.png'
import perceptionsPhoto from '../assets/wobbles/05.png'

const WOBBLE_HEIGHT = 90

export function Index2() {
  const [hoveredSection, setHoveredItemSection] = useState(null)
  const navigate = useNavigate()

  return (
    
    <div className="w-screen h-screen bg-whiteSmoke flex items-center justify-center p-6 flex-col">
      <VerticalIndexMenu4 /> 
      

      <div className="flex md:max-w-192 justify-center items-center" style={{ flex: 1 }}>
        {dataset.map((section) => {
          const isHovered = section.id === hoveredSection?.id
          const height = isHovered ? WOBBLE_HEIGHT : 0.5 * WOBBLE_HEIGHT

          return (
            <div
              key={section.id}
              className="group flex flex-col flex-1 items-center cursor-pointer max-h-96"
              onMouseOver={() => setHoveredItemSection(section)}
              onMouseOut={() => setHoveredItemSection(null)}
              onClick={() => navigate(`/${section.url}`)}
            >
              <div
                className="relative w-full flex justify-center h-0"
                style={{ paddingBottom: '100%' }}
              >
                {
                  {
                    [GEOGRAPHY_AND_CLIMATE]: (
                      <>
                        <WobblePlum
                          className="transition-maxHeight duration-100"
                          style={{ height, maxHeight: height, marginTop: 30,marginLeft:45 }}
                        />
                        <div className="absolute" style={{ top: '20%', left: '20%' }}>
                          <img
                            className="transition-all duration-200"
                            src={geographyPhoto}
                            alt="category photo"
                            style={{ transform: `scale(${isHovered ? 1 : 0})` }}
                          />
                        </div>
                      </>
                    ),
                    [ECONOMY]: (
                      <>
                        <WobblePumpkin
                          className="transition-maxHeight duration-100"
                          style={{ height, maxHeight: height, marginTop: 0,marginLeft:10  }}
                        />
                        <div className="absolute" style={{ top: '20%', left: '20%' }}>
                          <img
                            className="transition-all duration-200"
                            src={economyPhoto}
                            alt="category photo"
                            style={{ transform: `scale(${isHovered ? 1 : 0})` }}
                          />
                        </div>
                      </>
                    ),
                    [DEMOGRAPHICS]: (
                      <>
                        <WobblePea
                          className="transition-maxHeight duration-100"
                          style={{ height, maxHeight: height, marginTop: 50,marginLeft:5  }}
                        />
                        <div className="absolute" style={{ top: '20%', left: '20%' }}>
                          <img
                            className="transition-all duration-200"
                            src={demographicsPhoto}
                            alt="category photo"
                            style={{ transform: `scale(${isHovered ? 1 : 0})` }}
                          />
                        </div>
                      </>

                      
                    ),
                    [PEOPLE]: (
                      <>
                        <WobblePetroleum
                          className="transition-maxHeight duration-100"
                          style={{ height, maxHeight: height, marginTop: 20,marginLeft:5  }}
                        />
                        <div className="absolute" style={{ top: '20%', left: '20%' }}>
                          <img
                            className="transition-all duration-200"
                            src={lifePhoto}
                            alt="category photo"
                            style={{ transform: `scale(${isHovered ? 1 : 0})` }}
                          />
                        </div>
                        
                      </>
                    ),
                    [PERCEPTIONS_AND_ATTITUDES]: (
                      <>
                        <WobbleBrown
                          className="transition-maxHeight duration-100"
                          style={{ height, maxHeight: height, marginTop: 50,marginLeft:0  }}
                        />
                        <div className="absolute" style={{ top: '20%', left: '20%' }}>
                          <img
                            className="transition-all duration-200"
                            src={perceptionsPhoto}
                            alt="category photo"
                            style={{ transform: `scale(${isHovered ? 1 : 0})` }}
                          />
                        </div>
                      </>
                    ),
                  }[section.id]
                }
              </div>


              <div className="opacity-0 group-hover:opacity-100 transition-all duration-200 flex flex-col justify-end">
                <div
                  className={`font-bold text-lg text-${section.colorClass}Dark text-center pt-5 pb-4`}
                >
                  {section.name}
                </div>
                <div className="text-center" style={{ ...ellipsisTextLineStyle(5) }}>
                  {section.description}
                </div>
              </div>
            </div>
          )
        })}



      </div>

      
      
      <div className="absolute " style={{ top: '91%',fontSize: 10, textAlign:'center'}}> 
      <Logo2 className="absolute bottom-14 left-20 right-36 w-9 ml-3 mt-0 md:w-9 z-1"  />
     
      <div className="   " style={{ marginBottom:-15}}> 
      <h1>Produced by United Nations Development Programme</h1>
      <h1> Nigeria</h1>
      <h1>ⓒ Knowledge Hub 2023</h1>
      
      </div>
    </div>
    </div>
  )
}
