import React, { useState } from 'react'
import { downloadElementToImage } from '../utils/utils';
// import DownloadIcon from '@mui/icons-material/Download';
import { SquareTile } from '../components/chartContainers/SquareTile'
import { EconomicContributionChart } from '../components/smallCharts/EconomicContributionChart'
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';

import { SQUARE_TILE_SIZE } from '../lib/constants'
const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const EconomicContributionLegend = () => {
  const [isDownloading, setIsDownloading] = useState(false);


  const mockDatum = {
    sector: 'Agriculture',
    share: 24.04,
  }

  const handleCaptureDownoadClick = () => {
    const sectionComponent = document.getElementById("subsection");
    const snapshotElem = sectionComponent.getElementsByClassName("Economic_structure_component")?.[0];
    if (snapshotElem) {
      setIsDownloading(true);
      setTimeout(async () => {
        await downloadElementToImage(snapshotElem,"Economic_structure.png");
        setIsDownloading(false);
      }, 0)
    }
  }
  return (
    <div className="ml-0 flex flex-col gap-y-2">
    <div className="font-bold">How to read it</div>
      <div className="flex  gap-x-3 ">
      
        <SquareTile
          SquareTileChart={EconomicContributionChart}
          datum={mockDatum}
          size={SQUARE_TILE_SIZE}
          borderColor="#F2B572"
        />
         
        <div>
        <br/>
        <br/>
        
        
          <div className="text-black text-sm font-bold pt-13">Contribution to GDP(%)2022</div>
          <div className="text-black text-sm font-bold pt-12">Sector
          
          </div>
        </div>
        </div>
        <br/>
        <div  style={{ display: 'flex', alignItems: 'left',marginLeft:-10 }}>
        <button type="button" onClick={handleCaptureDownoadClick} style={{ color: 'rgb( 161, 81, 0)' }}disabled={isDownloading} className="flex items-center">
{isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
{!isDownloading && (
  <DownloadIcon
    className="ml-2"
    style={{  width: '26px', height: '26px', stroke: 'rgb( 161, 81, 0)', strokeWidth: '2' }} 
  />
)}
</button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
      
    </div>
  )
}
