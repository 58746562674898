const issuesColors = [
    "#B3988C",
    "#8D7268",
    "#F6E4D7",
    "#F6E4D7",
    "#CAB1A5",
    "#E1CBBE",
]

const GRID_SIZE = 50

export const SquaresGrid4 = ({ dataObj, heading }) => {

    return (
        <div className='flex flex-col items-start pr-5'>
            <div style={{ paddingTop: 8 }}>
                {heading}
            </div>
            <div className='grid grid-cols-3 gap-2'>
                {
                    Object.keys(dataObj).map((key, index) => (
                        <div
                            key={index}
                            className='flex flex-col justify-center items-center'
                            style={{
                                border: '1px solid #bdb6b1',
                                height: GRID_SIZE,
                                width: GRID_SIZE,
                                backgroundColor: `${issuesColors[index]}`,
                                fontSize: 12,
                                color: `${issuesColors[index] === '#CAB1A5' || issuesColors[index] === '#E1D6D4' ? '#FFFFFF' : '#FFFFFF'} `,
                                color: `${issuesColors[index] === '#F6E4D7' || issuesColors[index] === '#E1D6D4' ? '#77615B' : '#FFFFFF'} `,
                            }}
                        >
                            {dataObj[key].toFixed(2)}%
                        </div>
                    ))
                }
            </div>
        </div>
    )
}