// AgrDgrViz.js
import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'
import { HorizontalChart } from '../smallCharts/HorizontalChart'

export const AgrDgrViz = observer(({ chartType = 'agrDgr' }) => {
    const {
        data: { findConcernData },
        filter: { horizontalChartSelected }
    } = useMst()

    const containerRef = useRef(null)

    return (
        <div id="agrDgrVizContainer" ref={containerRef} className="" style={{ marginBottom: '0vh' }}>
            <div className='agreeordisagree-component grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-3 mx-20 pt-10 pb-10 gap-y-2'>
                <div className='flex flex-col col-span-3 xl:col-span-1'>
                    <div style={{ fontWeight: 800, fontSize: 15 }}>
                        Total
                    </div>
                    <HorizontalChart size={93} datum={findConcernData(chartType, horizontalChartSelected, "total", "total")} heading="&nbsp;" isInteractive={true} />
                </div>
                <div className='xl:col-span-2 flex flex-col'>
                    <div style={{ fontWeight: 800, fontSize: 15 }}>
                        Gender
                    </div>
                    <div className='flex-1' style={{ borderTop: '1px solid black', marginRight: 80 }}></div>
                    <div className='grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-2' style={{ fontSize: 14 }}>
                        <HorizontalChart size={93} datum={findConcernData(chartType, horizontalChartSelected, 'gender', 'male')} heading="Male" isInteractive={true} />
                        <HorizontalChart size={93} datum={findConcernData(chartType, horizontalChartSelected, 'gender', 'female')} heading="Female" isInteractive={true} />
                    </div>
                </div>
                <div className='col-span-3 flex flex-col'>
                    <div style={{ fontWeight: 800, fontSize: 15 }}>
                        Age Group
                    </div>
                    <div className='flex-1' style={{ borderTop: '1px solid black', marginRight: 80 }}></div>
                    <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3' style={{ fontSize: 14 }}>
                        <HorizontalChart size={93} datum={findConcernData(chartType, horizontalChartSelected, 'age', '18-34')} heading='18-34' isInteractive={true} />
                        <HorizontalChart size={93} datum={findConcernData(chartType, horizontalChartSelected, 'age', '35-60')} heading='35-60' isInteractive={true} />
                        <HorizontalChart size={93} datum={findConcernData(chartType, horizontalChartSelected, 'age', '61 years +')} heading='61 years +' isInteractive={true} />
                    </div>
                </div>
                <div className='col-span-3 flex flex-col'>
                    <div style={{ fontWeight: 800, fontSize: 15 }}>
                        Geopolitical Zone
                    </div>
                    <div className='flex-1' style={{ borderTop: '1px solid black', marginRight: 80 }}></div>
                    <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3' style={{ fontSize: 14 }}>
                        <HorizontalChart size={93} datum={findConcernData(chartType, horizontalChartSelected, 'zone', 'North Central')} heading='North Central' isInteractive={true} />
                        <HorizontalChart size={93} datum={findConcernData(chartType, horizontalChartSelected, 'zone', 'North East')} heading='North East' isInteractive={true} />
                        <HorizontalChart size={93} datum={findConcernData(chartType, horizontalChartSelected, 'zone', 'North West')} heading='North West' isInteractive={true} />
                        <HorizontalChart size={93} datum={findConcernData(chartType, horizontalChartSelected, 'zone', 'South East')} heading='South East' isInteractive={true} />
                        <HorizontalChart size={93} datum={findConcernData(chartType, horizontalChartSelected, 'zone', 'South South')} heading='South South' isInteractive={true} />
                        <HorizontalChart size={93} datum={findConcernData(chartType, horizontalChartSelected, 'zone', 'South West')} heading='South West' isInteractive={true} />
                    </div>
                </div>
            </div>
        </div>
    )
})
