import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMst } from '../../state';

export const LandUseVizFilter = observer(() => {
    const {
        filter: { landUseSelected, setLandUseSelected },
    } = useMst();

    const handleKeyDown = (event, dataType) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            setLandUseSelected(dataType);
        }
    };

    return (
        <div style={{ paddingTop: 20 }}>
            <div className="">
                {['Land Use %', 'Raster'].map((dataType) => {
                    const isSelected = landUseSelected === dataType;
                    return (
                        <div
                            key={dataType}
                            className={`flex items-center cursor-pointer activity-item ${isSelected ? 'selected font-bold' : ''}`}
                            onClick={() => setLandUseSelected(dataType)}
                            onKeyDown={(event) => handleKeyDown(event, dataType)}
                            tabIndex={0}
                            style={{
                                color: isSelected ? '#946886' : '#000000',
                                backgroundColor: isSelected ? '#ebe1e5' : 'transparent',
                                padding: '5px',
                            }}
                            aria-label={`Filter by ${dataType}`}
                        >
                            <div className={`${isSelected ? '' : 'w-0'}`} />
                            <div className="capitalize" style={{ fontSize: 20 }}>{dataType}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
});
