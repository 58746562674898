import React from 'react'

export const MultipleBarsBarchartcopy = ({
  groups,
  lineLabels,
  isMaplinechart,
  datum,
  width,
  height,
  lineLengthScale,
  colorScale,
}) => {
  // return groups.map((group, i) => {
  //   const textOffsetLeft =
  //     width / groups.length < 90 ? width / groups.length / 6 : width / groups.length / 2.5

  //   const lineHeight = lineLengthScale(datum[group])
  //   const linePercent = lineLabels ? datum[lineLabels[i]] : null
  //   return (
  //     <g key={`${group} `}>
  //       <rect 
  //         x={i * ( width / groups.length)*1.01}
  //         y={height - lineHeight}
  //         width={width / groups.length}
  //         height={lineHeight}
  //         fill={colorScale(group)}
  //       />

  //       {lineLabels && (
  //         <text
  //           y={isMaplinechart && linePercent > 40 ? height - 5 : height - lineHeight - 5}
  //           x={i * (width / groups.length) + textOffsetLeft}
  //           fontSize="11px"
  //           fill={isMaplinechart && linePercent > 40 ? '#FFFFFF' : '#000000'}
  //           opacity={linePercent === 0 ? 0.4 : 1}
  //         >
  //           {linePercent}%
  //         </text>
          
  //       )}
        
  //     </g>
     
  //   )
  // }
  // )
  return (
<>
<polyline
points={groups.map((group, i) =>{const lineHeight = lineLengthScale(datum[group]);
return (i * (width / groups.length) * 1.09).toString() + "," +(height - lineHeight).toString(); }).join(" ")}
stroke={colorScale(groups[0])}
style={{fill:"none",strokeWidth:1.5}}
      />
      {/* {
        groups.map((group, i) =>
          <rect 
            x={i * ( width / groups.length)*1.01 - 2}
            y={height - lineLengthScale(datum[group]) -2}
            width="4"
            height="4"
            fill={colorScale(group)}
          />
        )
      } */}
      {
        groups.map((group, i) =>
        <circle
          cx={i * (width / groups.length) * 1.08}
          cy={height - lineLengthScale(datum[group]) }
          r="1.5"
          //fill={colorScale(groups[0])}
          style={{fill:"#AB809E"}}
        />)
      }
    </>
  );
  
}
