import { types as t, getRoot } from 'mobx-state-tree'

export const FilterModel = t
  .model('FilterModel', {
    landUseSelected: 'Land Use %',
    landUseSelectedYear: 1975,
    importExportSelectedDataset: 'Import and export(USD billion)',
    GDPSelectedDataset: 'GDP(USD billion)',
    selected100PeopleCategory: 'Gender',
    selectedActivity: 'Working',
    selectedWorkCategory: 'Employment type',
    selectedFrequency: 'everyday',
    selectedIntention: 'extremely',
    selectedRegistration: 'Registered to vote',
    selectedState: 'Uyo, Akwa Ibom',
    concernSelected: 'The coronavirus pandemic',
    treeChartSelected: 'overallSatisfaction',
    treeChartSelected: 'significantIssues',
    SchoolsAndHealthCentersPerCapitaSelected:'Health facilities',
   
    horizontalChartSelected: ''
  
  })
  .views((self) => ({
    get root() {
      return getRoot(self)
    },
  }))
  .actions((self) => ({
    /**************************
     * Geography and Climate
     **************************/
    // 01 Land use
    setLandUseSelected(landUse) {
      self.landUseSelected = landUse
    },
    setLandUseSelectedYear(year) {
      self.landUseSelectedYear = year
    },
    setSelectedActivity(activity) {
      self.selectedActivity = activity
    },
    // 02 Urbanisation trend
    // 03 Road density per state
    // 04 Climate trends - precipitations
    // 05 Growth images of 6 selected cities
    setSelectedState(state) {
      self.selectedState = state
      console.log(state, 123, self.selectedState);
    },
    /**************************
     * Economy
     **************************/

    // Import export

    setImportExportSelectedDataset(label) {
      self.importExportSelectedDataset = label
    },
//School & Health Centers
    setSchoolsAndHealthCentersPerCapitaSelected(activity2) {
      self.SchoolsAndHealthCentersPerCapitaSelected = activity2
    },


      
    

//gdp
setGDPSelectedDataset(label) {
  self.GDPSelectedDataset = label
},

    /**************************
     * Demographics
     **************************/

    // 01 If Nigeria was 100 people

    setSelected100PeopleCategory(category) {
      self.selected100PeopleCategory = category
    },

    /**************************
     * People and Society
     **************************/

    // 02 what Nigerians do

    setSelectedWorkCategory(category) {
      self.selectedWorkCategory = category
    },

    // internet use
    setSelectedFrequency(frequency) {
      self.selectedFrequency = frequency
    },
    /**************************
     * Perceptions and Attitudes
     **************************/

    // 07 intention to vote
    setSelectedIntention(intention) {
      self.selectedIntention = intention
    },

    // 08 registration to vote
    setSelectedRegistration(registration) {
      self.selectedRegistration = registration
    },

    setConcernSelected(concern) {
      self.concernSelected = concern
    },
    setTreeChartSelected(concern) {
      self.treeChartSelected = concern
    },
    setHorizontalChartSelected(concern) {
      self.horizontalChartSelected = concern
    },
  }))
