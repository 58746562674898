import React from 'react'

export const MultipleLinesBarchart = ({ groups, datum, width, height, barLengthScale, fill }) => {
  return groups.map((group, i) => (
    <rect
      key={`${group}`}
      x={i * (width / groups.length*1.06)}
      y={height - barLengthScale(datum[group]) - 1}
      width={width / groups.length*0.94}
      height={1}
      fill={fill}
    />
  ))
}
