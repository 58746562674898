import React from 'react'
import { ReactCompareSlider, ReactCompareSliderImage, ReactCompareSliderHandle } from 'react-compare-slider';
import "./custom-slider.css"
const TemperatureByYear = ({ year }) => {
  const images = { 2000: 'Temperature_2000', 2020: 'Temperature_2020' }

  return (
    <div style={{  paddingTop:0 }}>
      {year === 2000 ? (
        <div className='flex justify-between'>
          <div>
            {year}
          </div>
          <div style={{ width: 100, backgroundColor: 'white' }}>
          </div>
        </div>
      ) : (
        <div className='flex justify-end'>
          {year}
        </div>
      )}
      <img height={200} src={`spatial/${images[year]}.jpg`} style={{ objectFit: 'contain' }} />
    </div>
  )
}

export const TemperatureSpatialViz = () => {

  return (
    <div style={{  paddingTop: 10 }}>
    <div className='pt-10 pr-10'>
      <ReactCompareSlider
        handle={<ReactCompareSliderHandle style={{ color: '#8e577d' }} />}
        itemOne={<TemperatureByYear year={2000} />}
        itemTwo={<TemperatureByYear year={2020} />}
      />
    </div>
    </div>
  )
  
}
