import React, { useState } from 'react'
import { downloadElementToImage } from '../utils/utils';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';
// import DownloadIcon from '@mui/icons-material/Download';
import { MapTile } from '../components/chartContainers/MapTile'

import { MAP_TILE_SIZE_DESKTOP } from '../lib/mapConstants'
import { DensityChart } from '../components/smallCharts/DensityChart'
const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const RoadDensityLegend = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const mockDatumDensity = {
    stateCode: 'LA',
    stateName: 'Lagos',
    geoZone: 'South West',
    density: 146,
  }

  const mockDatumLength = {
    stateCode: 'LA',
    stateName: 'Lagos',
    geoZone: 'South West',
    length: 5355,
  }

  const handleCaptureDownoadClick = () => {
    const sectionComponent = document.getElementById("subsection");
    const snapshotElem = sectionComponent.getElementsByClassName("map-component")?.[0];
    if (snapshotElem) {
      setIsDownloading(true);
      setTimeout(async () => {
        await downloadElementToImage(snapshotElem,"RoadDensity.png");
        setIsDownloading(false);
      }, 0)
    }
  }
  return (
    <div className="flex flex-col gap-y-8">
       <div className="font-bold">How to read it</div>
      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP,marginTop:-20 }}>
        <div className="absolute">
          <MapTile
            size={MAP_TILE_SIZE_DESKTOP}
            borderColor="#AB809E"
            MapTileChart={() => (
              <div className="absolute text-greyDark top-1 left-1 text-xs">LA</div>
            )}
          />
          <div
            className="absolute top-0 text-sm text-greyDark whitespace-nowrap font-medium"
            style={{ left: 90 }}
          >
            State code
          </div>
        </div>
      </div>

      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
        <div
          className="absolute"
          style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
        >
          <MapTile
            size={MAP_TILE_SIZE_DESKTOP}
            datum={mockDatumLength}
            borderColor="#AB809E"
            MapTileChart={DensityChart}
          />
          <div className="absolute top-0 text-xs text-greyDark" style={{ right: '102%' }}>
            120k
          </div>
          <div className="absolute bottom-0 text-xs text-greyDark" style={{ right: '102%' }}>
            0
          </div>
        </div>

        <div
          className="absolute text-sm text-greyDark font-semibold"
          style={{ left: MAP_TILE_SIZE_DESKTOP + 15, width: 125 }}
        >
          Total Road Length (in 1000 km)
          <div className="font-normal">Min: 1176 (BY)</div>
          <div className="font-normal">Max: 8505 (KD)</div>
        </div>
      </div>

      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
        <div
          className="absolute"
          style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
        >
          <MapTile
            size={MAP_TILE_SIZE_DESKTOP}
            datum={mockDatumDensity}
            borderColor="#AB809E"
            MapTileChart={DensityChart}
          />
        </div>

        <div
          className="absolute text-sm text-greyDark font-semibold"
          style={{ left: MAP_TILE_SIZE_DESKTOP + 15, width: 125 }}
        >
          Road Density (km of road per 100 km<sup>2</sup> of land area)
          <div className="font-normal">Min: 6 (TA)</div>
          <div className="font-normal">Max: 146 (LA)</div>
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'left' ,marginTop:20,marginLeft:-10}}>
      <button type="button" onClick={handleCaptureDownoadClick} disabled={isDownloading} className="flex items-center">
          {isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
          {!isDownloading && (
            <DownloadIcon
              className="ml-2"
              style={{  width: '26px', height: '26px', stroke: '#946886', strokeWidth: '2' }} 
            />
          )}
        </button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
    </div>
  )
}
