import React, { useState } from 'react'
import { downloadElementToImage } from '../utils/utils';
import html2canvas from 'html2canvas';

import { MapTile } from '../components/chartContainers/MapTile'
import { AreaChart } from '../components/smallCharts/AreaChart'

import { TEXT_SHADOW } from '../lib/styleUtils'
import { MAP_TILE_SIZE_DESKTOP } from '../lib/mapConstants'
import builtUp from '../dataset/builtUp.json'
// import DownloadIcon from '@mui/icons-material/Download';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';

const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const BuiltUpLegend = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const mockDatum = {
    1975: 14.47559409,
    2021: 34.36419837,
    stateCode: 'LA',
    stateName: 'Lagos',
    geoZone: 'South West',
  }

  const handleCaptureDownoadClick = () => {
    const sectionComponent = document.getElementById("subsection");
    const snapshotElem = sectionComponent.getElementsByClassName("map-component")?.[0];
    if (snapshotElem) {
      setIsDownloading(true);
      setTimeout(async () => {
        await downloadElementToImage(snapshotElem),"buildup.png";
        setIsDownloading(false);
      }, 0)
    }
  }

  return (
    <div className="flex flex-col gap-y-8">
      <div className="font-bold">How to read it</div>
      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP, marginTop: -20 }}>
        <div className="absolute">
          <MapTile
            size={MAP_TILE_SIZE_DESKTOP}
            borderColor="#AB809E"
            MapTileChart={() => (
              <div className="absolute text-greyDark top-1 left-1 text-xs">LA</div>
            )}
          />
          <div
            className="absolute top-0 text-sm text-greyDark whitespace-nowrap font-medium"
            style={{ left: 90 }}
          >
            State code
          </div>
        </div>
      </div>

      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
        <div
          className="absolute"
          style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
        >
          <MapTile
            size={MAP_TILE_SIZE_DESKTOP}
            dataset={builtUp}
            datum={mockDatum}
            borderColor="#AB809E"
            MapTileChart={AreaChart}
            latestYear={2021}
            oldestYear={1975}
            chartFill="#AB809E"
          />

          <div
            className="absolute right-1 top-1 text-black text-sm font-bold"
            style={{ textShadow: TEXT_SHADOW }}
          >
            29%
          </div>

          <div className="absolute top-0 text-xs text-greyDark" style={{ right: '102%' }}>
            40%
          </div>
          <div className="absolute bottom-0 text-xs text-greyDark" style={{ right: '102%' }}>
            0%
          </div>

          <div className="absolute left-0 text-xs text-greyDark" style={{ top: '105%' }}>
            1975
          </div>
          <div className="absolute text-xs text-greyDark" style={{ top: '105%', left: '80%' }}>
            2020
          </div>
        </div>
        <div
          className="absolute text-sm text-greyDark font-semibold"
          style={{ left: MAP_TILE_SIZE_DESKTOP + 15, width: 120 }}
        >
          Built-up area in 2020 (%)
          <div className="font-normal">Min: 0.80% (YO)</div>
          <div className="font-normal">Max: 29% (LA)</div>
        </div>
      </div>

      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
        <div
          className="absolute"
          style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
        >
          <MapTile
            size={MAP_TILE_SIZE_DESKTOP}
            dataset={builtUp}
            datum={mockDatum}
            borderColor="#AB809E"
            MapTileChart={AreaChart}
            latestYear={2021}
            oldestYear={1975}
            chartFill="#AB809E"
          />

          <div
            className="absolute left-1 bottom-0.5 text-greyDark text-xs"
            style={{ textShadow: TEXT_SHADOW }}
          >
            15%
          </div>

          <div className="absolute top-0 text-xs text-greyDark" style={{ right: '103%' }}>
            40%
          </div>
          <div className="absolute bottom-0 text-xs text-greyDark" style={{ right: '103%' }}>
            0%
          </div>

          <div className="absolute left-0 text-xs text-greyDark" style={{ top: '105%' }}>
            1975
          </div>
          <div className="absolute text-xs text-greyDark" style={{ top: '105%', left: '80%' }}>
            2020
          </div>
        </div>
        <div
          className="absolute text-sm text-greyDark font-semibold"
          style={{ left: MAP_TILE_SIZE_DESKTOP + 15, width: 120 }}
        >
          Built-up area in 1975 (%)
          <div className="font-normal">Min: 0.09% (FC)</div>
          <div className="font-normal">Max: 15% (LA)</div>
        </div>
      </div>
      <div style={{ display: 'flex',  alignItems: 'left' ,marginTop:15,marginLeft:-10}}>
      <button type="button" onClick={handleCaptureDownoadClick} disabled={isDownloading} className="flex items-center">
          {isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
          {!isDownloading && (
            <DownloadIcon
              className="ml-2"
              style={{  width: '26px', height: '26px', stroke: '#946886', strokeWidth: '2' }} 
            />
          )}
        </button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
    </div>
  )
}
