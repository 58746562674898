import { observer } from 'mobx-react-lite'
import React from 'react'
export const PrecipitationsSummerystatus = observer(() => {

 
  return (
    <div className=" ">
      <div style={{ borderTop: '1px solid lightgray',marginLeft:-10 ,marginBottom:0}}>   </div> 
      
        <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 0 }}>

 <table className=' border-b    flex-1 ' style={{  marginRight: 0,maxWidth:3000 ,minHeight:40 }}>
 <tr >
 <th ></th>
 <th></th>
 <th></th>
 <th></th>
 </tr>

 
 <tr>
 
 {/* <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Average change in annual precipitation </tr>
 <tr class="custom-Geography2"> (2000-2020 to 2021-2022): -1.2% </tr>
 </td> */}
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Driest Month</tr>
 <tr class="custom-Geography2"> December</tr>
 <tr style={{ fontSize: 12 ,fontWeight: 500,padding:3,color: '#FFFFFF'}}>. </tr>
 <tr style={{ fontSize: 12 ,fontWeight: 500,padding:3,color: '#FFFFFF'}}> .</tr>
 </td>
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Wettest Month</tr>
 <tr class="custom-Geography2"> September</tr>
 <tr style={{ fontSize: 12 ,fontWeight: 500,padding:3,color: '#FFFFFF'}}> .</tr>
 <tr style={{ fontSize: 12 ,fontWeight: 500,padding:3,color: '#FFFFFF'}}> .</tr>
 </td>
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Driest States</tr>
 <tr class="custom-Geography2">  Sokoto</tr>
 <tr class="custom-Geography2">  Yobe</tr>
 <tr class="custom-Geography2">   Jigawa</tr>
 </td>


 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Wettest States</tr>
 <tr class="custom-Geography2"> Bayelsa</tr>
 <tr class="custom-Geography2">  Rivers</tr>
 <tr class="custom-Geography2"> Akwa Ibom</tr>
 </td>

 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Greatest increase in ave. prcp</tr>
 <tr class="custom-Geography2"> Yobe</tr>
 <tr class="custom-Geography2"> Jigawa</tr>
 <tr class="custom-Geography2">  Oyo</tr>
 </td>

 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1">Greatest decrease in ave prcp</tr>
 <tr class="custom-Geography2">Cross River</tr>
 <tr class="custom-Geography2"> Akwa Ibom</tr>
 <tr class="custom-Geography2"> Ebonyi</tr>
 </td>

 
 
 </tr>
 
 </table>
 <div  className='flex-1 text-greyDark' style={{ fontSize: 10 ,minHeight:29,fontWeight:500,marginTop: 3  }}>
Source: National Aeronautics and Space Administration (NASA) Langley Research Center (LaRC) Prediction of Worldwide Energy Resource (POWER)Project funded through the NASA Earth Science/Applied Science Program: Monthly & Annual Precipitation, POWER Data Access Viewer v2.0.0,1981-2020. Available  <a class="custom-link" href="https://developers.google.com/earth-engine/datasets/catalog/MODIS_061_MOD11A2" target="_blank" > here</a> 

 
      
</div>
 </div>
 
 
      
 
      </div>

      


  )

})
