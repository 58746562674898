import { observer } from 'mobx-react-lite'
import React from 'react'
export const GDPSummerystatus = observer(() => {

  
  return (
    <div className=" "style={{ marginTop: 0 }}>
      <div style={{ borderTop: '1px solid lightgray',marginLeft:-10 ,marginBottom:-10}}>   </div> 
      
        <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 0 }}>

 <table className=' border-b    flex-1 ' style={{  marginRight: 0,maxWidth:3000,minHeight:66  }}>
 <tr >
 <th ></th>
 <th></th>
 <th></th>
 <th></th>
 </tr>

 
 <tr>
 <td style={{ minWidth:0 }}>
 <tr class="custom-Economy1"> Nigeria’s GDP is ranked highest in Africa and 31st globally in 2022. </tr>
 <tr style={{ fontSize: 12 ,minWidth:400,fontWeight: 500,padding:3}}> </tr>
 </td>
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Economy1"> Nigeria’s GDP per capita is ranked 17th in Sub-Saharan Africa, 23rd in Africa and 152 globally in 2022 (out of 192 countries)</tr>
 <tr style={{ fontSize: 12 ,minWidth:400,fontWeight: 500,padding:3}}> </tr>
 </td>
  
 
 
 
 </tr>
 
 </table>
 <div  className='flex-1 text-greyDark' style={{ fontSize: 10 ,minHeight:40,fontWeight:500,marginTop: 5  }}>
 Source: International Monetary Fund. 2022. World Economic Outlook. Available <a class="custom-link" href=" https://www.imf.org/en/Publications/WEO/weo-database/2022/October" target="_blank" > here</a>   [Accessed March 2023].

 
      
</div>
 </div>
 
 
      
 
      </div>

      


  )

})
