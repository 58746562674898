import { observer } from 'mobx-react-lite'
import React from 'react'
export const DailyPowerSummerystatus = observer(() => {

 
  return (
    <div className=" ">
      
      
        <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 0,marginBottom: 10 }}>


 <div  className='flex-1 text-greyDark' style={{ borderTop: '1px solid lightgray',fontSize: 10 ,fontWeight:500,marginTop: 5  }}>


 <div  className='flex-1 text-greyDark' style={{ textTransform: 'uppercase',fontSize: 11 ,fontWeight:600,marginTop: 5  }}>
 Percentages shown for among those households with access to electricity
 </div>
 
Source: United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.

 
      
</div>
 </div>
 
 
      
 
      </div>

      


  )

})
