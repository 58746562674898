import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'
import { ChartContent } from './ChartContent'
import { Map } from '../chartContainers/Map'
import { SemicircleChart } from '../smallCharts/SemicircleChart'
import { ReactCompareSlider, ReactCompareSliderHandle } from 'react-compare-slider';
import "./custom-slider.css"
import { HealthCentersPerCapitaViz } from './HealthCentersPerCapitaViz'




const SchoolsAndHealthCentersPerCapita = ({ year }) => {
  const {
    data: { schoolsAndHealthCentersPerCapita, privateSchoolsAndHealthCentersPerCapita },
  } = useMst()

  return (
    <div style={{ paddingTop: 20}}>
     
      <div style={{ background: 'white' }}>
        <ChartContent
          isMapChart={true}
          chart={
            <Map
              dataset={year === 'public' ? schoolsAndHealthCentersPerCapita : privateSchoolsAndHealthCentersPerCapita}
              isInteractive={false}
              borderColor="transparent"
              fillColor="#F5F4F4"
              semicircleLabels={['public', 'private']}
              MapTileChart={SemicircleChart}
            />
          }
        />
      </div>
    </div>
  )
}

export const SchoolsAndHealthCentersPerCapitaViz = observer(() => {
  
  const {
    filter: { SchoolsAndHealthCentersPerCapitaSelected },
  } = useMst()

  return (
      
      <div style={{ height: '90vh' }}>
{SchoolsAndHealthCentersPerCapitaSelected === 'Health facilities' ? (
        <SchoolsAndHealthCentersPerCapita
          
        
          itemTwo={<SchoolsAndHealthCentersPerCapita year={'private'} />}
        />) 
        :
        ( <HealthCentersPerCapitaViz /> )
         
       
        }

      </div>

  )
})