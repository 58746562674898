import React from 'react'
import { addPlusSign } from '../../lib/formatUtils'
import { TEXT_SHADOW } from '../../lib/styleUtils'
import { AreaChart } from './AreaChart'

export const PopulationChart = ({ dataset, datum, size, latestYear, oldestYear, chartFill }) => {
  return (
    <>
      <AreaChart
        dataset={dataset}
        datum={datum}
        size={size}
        latestYear={latestYear}
        oldestYear={oldestYear}
        chartFill={chartFill}
      />

<div
className="absolute left-1 top-5 text-black text-sm font-bold"
       
        style={{ textShadow: TEXT_SHADOW }}
      >
        
        +{datum.pctChange.toFixed(0)}%  
      </div>

<div
         className="absolute right-1 bottom-1 text-black text-xs font-bold"
        style={{ textShadow: TEXT_SHADOW }}
      >
       {datum[latestYear] >= 100 ? Math.round(datum[latestYear]) : datum[latestYear].toFixed(0)}
      </div>

      
      
    </>
  )
}

{/* <div
className="absolute right-1 top-1 text-black text-sm font-bold"
style={{ textShadow: TEXT_SHADOW }}
>
{datum[latestYear] >= 100 ? Math.round(datum[latestYear]) : datum[latestYear].toFixed(0)}%
</div>

<div
className="absolute left-1 bottom-1 text-greyDark text-xs"
style={{ textShadow: TEXT_SHADOW }}
>
{datum[oldestYear] >= 100 ? Math.round(datum[oldestYear]) : datum[oldestYear].toFixed(0)}%
</div>
</>
)
} */}