import { observer } from 'mobx-react-lite'
import React from 'react'
export const WhatNigeriansAskSummerystatus = observer(() => {

  
  return (
    <div className=" "style={{ marginTop:0 }}>
      
      
        <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 0 }}>

 
        <div  className='flex-1 text-greyDark' style={{borderTop: '1px solid lightgray', fontSize: 10 ,fontWeight:500,marginTop: 5,minHeight:50  }}>
<br/>Source: Google trends. Available <a class="custom-link" href="https://trends.google.com/trends/?geo=NG" target="_blank" > here</a>

 
      
</div>
 </div>
 
 
      
 
      </div>

      


  )

})
