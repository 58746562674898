import React from 'react'
import { scaleLinear } from 'd3-scale'

import { BarchartTile } from './BarchartTile'
import { BARCHARTS_GRID_TILE_SIZE } from '../../lib/constants'

export const SimpleBarchartsGrid = ({ dataset, xLabel, barLabels, groups, colorScale }) => {
  const padding = 50
  const leftPadding = 10
  const tileSize = BARCHARTS_GRID_TILE_SIZE
  const height = tileSize * (2 / 3)
  const barLengthScale = scaleLinear()
    .domain([0, 100])
    .range([0, height - 5])

  return (
    <div className="pt-8">
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fill, ${tileSize}px)`,
          gap: padding,
          padding: padding,
          paddingLeft: leftPadding
        }}
      >
        {dataset.map((datum, index) => {
          return (
            <div className="relative" key={index}>
              <BarchartTile
                size={tileSize}
                datum={datum}
                groups={groups}
                barLabels={barLabels}
                xLabel={xLabel}
                barLengthScale={barLengthScale}
                colorScale={colorScale}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
