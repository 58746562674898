import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { Map } from '../chartContainers/Map'
import { IntentionToVoteChart } from '../smallCharts/IntentionToVoteChart'

export const IntentionToVoteViz = observer(() => {
  const {
    data: { intentionToVoteByLikeliness, intentions },
    filter: { selectedIntention, setSelectedIntention },
  } = useMst()

  return (
    <ChartContent
      isMapChart={true}
      Filters={() => {
        return (
          <div className="">
            <div className="" style={{ color: 'rgba(0, 0, 0, 1)' }}>
              Intention displayed
            </div>
            {intentions.map((intention) => {
              const isSelected = selectedIntention === intention
              return (
                <div
                  key={intention}
                  className={`flex items-center cursor-pointer ${isSelected ? 'font-bold' : ''}`}
                  onClick={() => setSelectedIntention(intention)}
                >
                  <div
                    className={`bg-black ${isSelected ? 'w-6 mr-1' : 'w-0'}`}
                    style={{ height: 1 }}
                  />
                  <div className="capitalize">
                    {intention === 'unknown' ? "Don't know" : `${intention} Likely`}{' '}
                  </div>
                </div>
              )
            })}
          </div>
        )
      }}
      chart={
        <Map
          dataset={intentionToVoteByLikeliness(selectedIntention)}
          borderColor="#B2978B"
          MapTileChart={IntentionToVoteChart}
        />
      }
      Legend={() => null}
    />
  )
})
