import { observer } from 'mobx-react-lite';
import React, { useState } from 'react'
import { useMst } from '../../state';

export const HorizontalChart = observer(({ datum, size, borderColor = '#B2978B', heading = "", isInteractive = false }) => {
    const {
        filter: { horizontalChartSelected }
    } = useMst()
    let agreeRight = size * datum['Agree'] * 0.01;
    let neitherRight = agreeRight + size * datum['Neither'] * 0.01;
    let disagreeRight = neitherRight + size * datum['Disagree'] * 0.01;
    let dontKnowRight = disagreeRight + size * datum['Dont know/Refused'] * 0.01;

    const [tileHover, setTileHover] = useState(false)
    return (
        <div>
            <div style={{ paddingTop: 8 }}>
                {heading}
            </div>
            <div
                style={{ width: size + 2, height: size + 2, border: `1px solid ${borderColor}`, position: 'relative' }}
                onMouseEnter={() => {
                    setTileHover(true)
                }}
                onMouseLeave={() => {
                    setTileHover(false)
                }}
            >
                <div style={{ right: size - agreeRight, height: size, background: '#4a3527', bottom: 0, left: 0, position: 'absolute' }}>
                </div>
                <div style={{ right: size - neitherRight, height: size, background: '#8b6248', bottom: 0, left: agreeRight, position: 'absolute' }}>
                </div>
                <div style={{ right: size - disagreeRight, height: size, background: '#b78f76', bottom: 0, left: neitherRight, position: 'absolute' }}>
                </div>
                <div style={{ right: size - dontKnowRight, height: size, background: '#c8a996', bottom: 0, left: disagreeRight, position: 'absolute' }}>
                </div>
                {/* <div style={{ position: 'absolute', bottom: 0, left: 2, color: 'white', fontSize: 12 }}>{datum}%</div> */}
                {isInteractive && tileHover && (
                    <div style={{ position: 'absolute', bottom: size / 2 - 10, right: size + 10, border: '1px solid #B2978B', background: 'white', width: 250, height: 135, zIndex: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ padding: 10 }}>
                                <div style={{ fontSize: 15, fontWeight: 1000, paddingBottom: 15 }}>
                                    {heading === "\u00A0" ? 'Total' : heading}
                                </div>
                                <div className="legend-element flex justify-between">
                                    <div className="flex gap-x-1">
                                        <div className="square w-4 h-4" style={{ background: '#4a3527' }} />
                                        <div className="label text-sm">Agree</div>
                                    </div>
                                    <div className="percentage text-sm font-semibold">{datum['Agree'].toFixed(2)}%</div>
                                </div>
                                {(horizontalChartSelected !== 'I feel safe walking alone in my neigbourhood after dark') && (
                                    <div className="legend-element flex justify-between">
                                        <div className="flex gap-x-1">
                                            <div className="square w-4 h-4" style={{ background: '#8b6248' }} />
                                            <div className="label text-sm">Neither</div>
                                        </div>
                                        <div className="percentage text-sm font-semibold">{datum['Neither'].toFixed(2)}%</div>
                                    </div>
                                )}
                                <div className="legend-element flex justify-between">
                                    <div className="flex gap-x-1">
                                        <div className="square w-4 h-4" style={{ background: '#b78f76' }} />
                                        <div className="label text-sm">Disagree</div>
                                    </div>
                                    <div className="percentage text-sm font-semibold">{datum['Disagree'].toFixed(2)}%</div>
                                </div>
                                {(horizontalChartSelected !== 'Generally speaking most people I interact with can be trusted' && horizontalChartSelected !== 'I feel safe walking alone in my neigbourhood after dark' && horizontalChartSelected !== 'In general, men make better political leaders than women') ? (
                                    <div className="legend-element flex justify-between">
                                        <div className="flex gap-x-1">
                                            <div className="square w-4 h-4" style={{ background: '#c8a996' }} />
                                            <div className="label text-sm">Dont know/Refused</div>
                                        </div>
                                        <div className="percentage text-sm font-semibold">{datum['Dont know/Refused'].toFixed(2)}%</div>
                                    </div>

                                ) : null}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
})
