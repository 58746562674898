import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { TreeChart2 } from '../smallCharts/TreeChart2'

export const TreeChartViz2 = observer(({ chartType = 'significantIssues' }) => {
    const {
        data: { findConcernData },
        filter: { treeChartSelected }
    } = useMst()
    const containerRef = useRef(null)

    return (
        <div id="TreeChartViz2Container" ref={containerRef} className="" style={{ marginBottom: '0' }}>
         <div className='abc-component grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-3 mx-20 pt-10 pb-10 gap-y-2'>
         <div className='flex flex-col col-span-3 xl:col-span-1'>
            <div style={{ fontWeight: 800 ,fontSize:15 }}>
                    Total
                </div>
                <TreeChart2 chartType={chartType} size={93} datum={findConcernData(chartType, treeChartSelected, "total", "total")} heading="&nbsp;" isInteractive={true} />
            </div>
            <div className='xl:col-span-2 flex flex-col'>
            <div style={{ fontWeight: 800 ,fontSize:15 }}>
                    Gender
                </div>
                <div className='flex-1' style={{ borderTop: '1px solid black', marginRight: 80 }}></div>
                <div className='grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 'style={{ fontSize:14 }}>
                    <TreeChart2 chartType={chartType} size={93} datum={findConcernData(chartType, treeChartSelected, 'gender', 'male')} heading="Male" isInteractive={true} />
                    <TreeChart2 chartType={chartType} size={93} datum={findConcernData(chartType, treeChartSelected, 'gender', 'female')} heading="Female" isInteractive={true} />
                </div>
            </div>
            <div className='col-span-3 flex flex-col'>
            <div style={{ fontWeight: 800 ,fontSize:15 }}>
                    Age Group
                </div>
                <div className='flex-1' style={{ borderTop: '1px solid black', marginRight: 80 }}></div>
                <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 'style={{ fontSize:14 }}>
                    <TreeChart2 chartType={chartType} size={93} datum={findConcernData(chartType, treeChartSelected, 'age', '18-34')} heading='18-34' isInteractive={true} />
                    <TreeChart2 chartType={chartType} size={93} datum={findConcernData(chartType, treeChartSelected, 'age', '35-60')} heading='35-60' isInteractive={true} />
                    <TreeChart2 chartType={chartType} size={93} datum={findConcernData(chartType, treeChartSelected, 'age', '61 years +')} heading='61 years +' isInteractive={true} />
                </div>
            </div>
            <div className='col-span-3 flex flex-col'>
            <div style={{ fontWeight: 800 ,fontSize:15 }}>
                    Geopolitical Zone
                </div>
                <div className='flex-1' style={{ borderTop: '1px solid black', marginRight: 80 }}></div>
                <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 ' style={{ fontSize:14 }}>
                    <TreeChart2 chartType={chartType} size={93} datum={findConcernData(chartType, treeChartSelected, 'zone', 'North Central')} heading='North Central' isInteractive={true} />
                    <TreeChart2 chartType={chartType} size={93} datum={findConcernData(chartType, treeChartSelected, 'zone', 'North East')} heading='North East' isInteractive={true} />
                    <TreeChart2 chartType={chartType} size={93} datum={findConcernData(chartType, treeChartSelected, 'zone', 'North West')} heading='North West' isInteractive={true} />
                    <TreeChart2 chartType={chartType} size={93} datum={findConcernData(chartType, treeChartSelected, 'zone', 'South East')} heading='South East' isInteractive={true} />
                    <TreeChart2 chartType={chartType} size={93} datum={findConcernData(chartType, treeChartSelected, 'zone', 'South South')} heading='South South' isInteractive={true} />
                    <TreeChart2 chartType={chartType} size={93} datum={findConcernData(chartType, treeChartSelected, 'zone', 'South West')} heading='South West' isInteractive={true} />
                </div>
            </div>
        
        </div>
        </div>
    )
})
