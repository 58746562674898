import { observer } from 'mobx-react-lite'
import React from 'react'
export const TemperatureSpatialSummerystatus = observer(() => {

  
  return (
    <div className=" ">
      <div style={{ borderTop: '1px solid lightgray',marginLeft:-10 ,marginBottom:-11}}>   </div> 
      
        <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 0 }}>

 <table className=' border-b    flex-1 ' style={{  marginRight: 0,maxWidth:3000,minHeight:50  }}>
 <tr >
 <th ></th>
 <th></th>
 <th></th>
 <th></th>
 </tr>

 
 <tr>
 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Change in surface temperature (by geopolitical zone)</tr>
 
 </td>
 


 
 
 <td style={{ minWidth:0 }}>

 <tr class="custom-Geography2"> NC: 2.71% ,  NE: 13.46%,  NW: 11.76% ,  SE: -8.76% ,  SS: -12.27% ,  SW: -7.22%
 </tr>
 </td>
 


 








 
 
 </tr>
 
 </table>
 <div  className='flex-1 text-greyDark' style={{ fontSize: 10 ,minHeight:30,fontWeight:500,marginTop: 3  }}>
Source: Wan, Z., S. Hook, G. Hulley. MODIS/Terra Land Surface Temperature/Emissivity Daily L3 Global 1km SIN Grid V061. 2021, distributed by NASA EOSDIS Land Processes DAAC. Available <a class="custom-link" href="https://doi.org/10.5067/MODIS/MOD11A1.061" target="_blank" >here</a>   . Accessed 2022-10-06.

 
      
</div>
 </div>
 
 
      
 
      </div>

      


  )

})
