import React from 'react'

import { TEMPERATURE_SPATIAL_COLOR_SCALE } from '../lib/constants'

export const TemperatureSpatialLegend = () => {
  return (
    <div className="ml-10 flex flex-col gap-y-2">
         <div className="font-bold">How to read it</div>
      <div className="flex flex-col gap-y-1">
        {TEMPERATURE_SPATIAL_COLOR_SCALE.domain().map((label) => (
          <div className="legend-element flex justify-between" key={label}>
            <div className="flex gap-x-1">
              <div
                className="square w-4 h-4"
                style={{ background: TEMPERATURE_SPATIAL_COLOR_SCALE(label) }}
              />
              <div className="label text-sm">{label}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
