import { observer } from 'mobx-react-lite'

import { useMst } from '../state'
import { schoolsHealthCentersLegend } from '../lib/legendSchoolandHealthUtils'

export const SchoolsHealthCentersLegend = observer(() => {
  const {
    filter: { SchoolsAndHealthCentersPerCapitaSelected },
  } = useMst()

  return schoolsHealthCentersLegend [SchoolsAndHealthCentersPerCapitaSelected]
})
