import React from 'react'

export const MultipleBarsBarchart = ({
  groups,
  barLabels,
  isMapBarchart,
  datum,
  width,
  height,
  barLengthScale,
  colorScale,
}) => {
  return groups.map((group, i) => {
    const textOffsetLeft =
      width / groups.length < 90 ? width / groups.length / 6 : width / groups.length / 2.5

    const barHeight = barLengthScale(datum[group])
    const barPercent = barLabels ? datum[barLabels[i]] : null
    return (
      <g key={`${group}`}>
        <rect
          x={i * ( width / groups.length)*1.01}
          y={height - barHeight}
          width={width / groups.length*0.9}
          height={barHeight}
          fill={colorScale(group)}
        />

        {barLabels && (
          <text
            y={isMapBarchart && barPercent > 40 ? height - 5 : height - barHeight - 5}
            x={i * (width / groups.length) + textOffsetLeft}
            fontSize="11px"
            fill={isMapBarchart && barPercent > 40 ? '#FFFFFF' : '#000000'}
            opacity={barPercent === 0 ? 0.4 : 1}
          >
            {Math.round(barPercent)}%
          </text>
        )}
      </g>
    )
  })
}
