import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { SimpleBarchartsGrid } from '../chartContainers/SimpleBarchartsGrid'

import { TRANSPORT_CLUSTERS, TRANSPORT_COLOR_SCALE } from '../../lib/constants'

export const GettingAroundViz = observer(() => {
  const {
    data: { gettingAround },
  } = useMst()

  return (
    <ChartContent
      chart={
        <SimpleBarchartsGrid
          dataset={gettingAround}
          colorScale={TRANSPORT_COLOR_SCALE}
          xLabel="response"
          barLabels={TRANSPORT_CLUSTERS}
          groups={TRANSPORT_CLUSTERS}
        />
      }
    />
  )
})
