import { observer } from 'mobx-react-lite';
import React from 'react';
import { useMst } from '../../state';

export const DayInTheLifeVizFilter = observer(() => {
    const {
        data: { dailyActivities },
        filter: { selectedActivity, setSelectedActivity },
    } = useMst();

    const handleKeyDown = (event, activity) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            setSelectedActivity(activity);
        }
    };

    return (
        <div style={{ paddingTop: 40 }}>
            <h2 style={{ fontWeight: 900, fontSize: '18px', color: '#2F9991', marginBottom: '4px', textAlign: 'center' }}>
                Average time spent on main activities
            </h2>
            {dailyActivities.map((activity) => {
                const isSelected = selectedActivity === activity;
                return (
                    <div
                        key={activity}
                        className={`flex items-center cursor-pointer activity-item ${isSelected ? 'selected font-bold' : ''}`}
                        onClick={() => setSelectedActivity(activity)}
                        onKeyDown={(event) => handleKeyDown(event, activity)}
                        tabIndex={0}  // Makes the item focusable
                        role="button"  // Indicates that the element behaves like a button
                        aria-pressed={isSelected}  // Indicates whether the item is selected
                        aria-label={`Select ${activity}`}  // Provides a descriptive label for screen readers
                        style={{ color: isSelected ? '#2F9991' : '#000000', backgroundColor: isSelected ? '#e0f4f3' : 'transparent', padding: '5px' }}
                    >
                        <div
                            className={`bg-black ${isSelected ? '' : 'w-0'}`}
                            style={{ height: 1 }}
                        />
                        <div className="capitalize" style={{ fontSize: 20 }}>{activity}</div>
                    </div>
                );
            })}
        </div>
    );
});
