import React from 'react';
import { AnimatedDataset } from 'react-animated-dataset';
import { times, pick } from 'lodash-es';

export const Verso = ({ dataset }) => {
  let indexSum = 0;
  const versoDataset = dataset.flatMap((datum) =>
    times(datum.value).map(() => {
      return {
        ...pick(datum, ['category', 'label', 'angle', 'fill', 'border']),
        indexSum: indexSum++,
      };
    })
  );

  // Determine chart size and pill size based on screen width
  const isMobile = window.innerWidth <= 768;
  const chartSize = isMobile ? 350 : 450; // Smaller chart size for mobile devices
  const pillWidth = isMobile ? 22 : 27; // Smaller pill width for mobile devices
  const pillHeight = isMobile ? 22 : 27; // Smaller pill height for mobile devices
  const initialAngle = 0;

  function calculateX(sum) {
    return (sum % 10) * (chartSize / 10);
  }

  function calculateY(sum) {
    return Math.floor(sum / 10) * (chartSize / 10);
  }

  return (
    <div className="flex flex-col pl-2 items-center">
      <svg width={chartSize} height={chartSize} overflow="visible">
        <AnimatedDataset
          dataset={versoDataset}
          tag="rect"
          attrs={{
            x: 0,
            y: 0,
            rx: pillWidth / 2,
            fill: (d) => d.fill,
            stroke: (d) => d.border,
            strokeWidth: 1,
            width: pillWidth,
            height: pillHeight,
            transform: (d) =>
              `translate(${calculateX(d.indexSum)} ${calculateY(d.indexSum)}) rotate(${d.angle}, ${pillWidth / 2}, ${pillHeight / 2})`,
          }}
          init={{
            transform: (d) => {
              return `translate(${calculateX(d.indexSum)} ${calculateY(d.indexSum)}) rotate(${initialAngle}, ${pillWidth / 2}, ${pillHeight / 2}) `;
            },
          }}
          duration={1000}
          keyFn={(d) => d.indexSum}
        />
      </svg>
    </div>
  );
};
