import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { SimpleBarchartsGrid } from '../chartContainers/SimpleBarchartsGrid'

import { AGE_CLUSTERS, WORK_TYPE_COLOR_SCALE } from '../../lib/constants'

export const WhatNigeriansDoViz = observer(() => {
  const {
    data: { workCategories, whatNigeriansDoByCategory },
    filter: { selectedWorkCategory, setSelectedWorkCategory },
  } = useMst()

  return (
    <ChartContent
      Filters={() => {
        return (
          <div className="">
            <div className="" style={{ color: 'rgba(0, 0, 0, 1)' }}>
              Category displayed
            </div>
            {workCategories.map((category) => {
              const isSelected = selectedWorkCategory === category
              return (
                <div
                  key={category}
                  className={`flex items-center cursor-pointer ${isSelected ? 'font-bold' : ''}`}
                  onClick={() => setSelectedWorkCategory(category)}
                >
                  <div
                    className={`bg-black ${isSelected ? 'w-6 mr-1' : 'w-0'}`}
                    style={{ height: 1 }}
                  />
                  <div>{category}</div>
                </div>
              )
            })}
          </div>
        )
      }}
      chart={
        <SimpleBarchartsGrid
          dataset={whatNigeriansDoByCategory(selectedWorkCategory)}
          colorScale={WORK_TYPE_COLOR_SCALE}
          xLabel="jobType"
          barLabels={AGE_CLUSTERS}
          groups={WORK_TYPE_COLOR_SCALE.domain()}
        />
      }
    />
  )
})
