import React, { useEffect, useRef, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useMst } from '../state';
import { PLUM, PUMPKIN, PEA, PETROLEUM, BROWN } from '../lib/constants';
import { WobbleBubble } from './WobbleBubble';
import { Footer } from './Footer';
import Header from './Header';

const SIZE = 126;

export const Landing = observer(() => {
  const { ui: { isMobile } } = useMst();
  const navigate = useNavigate();
  const containerNodeRef = useRef(null);
  const cursorRef = useRef(null);

  const updateMouse = (e) => {
    if (cursorRef.current !== null) {
      cursorRef.current.style.top = `${e.y - SIZE / 4}px`;
      cursorRef.current.style.left = `${e.x - SIZE / 4}px`;
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateMouse);
    return () => {
      window.removeEventListener('mousemove', updateMouse);
    };
  }, []);

  const goToIndex = () => {
    const route = isMobile ? `/index` : '/index';
    navigate(route);
  };

  return (
    <>
      <Header />
      {renderContent()}
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </>
  );

  function renderContent() {
    return (
      <div
        ref={containerNodeRef}
        className="relative w-full h-screen flex flex-col justify-center items-center bg-whiteSmoke overflow-hidden"
        onClick={goToIndex}
      >
        <div className="font-black text-6xl md:text-7xl text-center z-10 font-soehne-breit responsive-text">
          NIGERIA VISUALISED
        </div>

        <div className="font-bold text-lg md:text-2xl pt-7 z-10"></div>

        <Cursor
          ref={cursorRef}
          fixed={isMobile}
          onClick={goToIndex} 
          tabIndex="0" 
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              goToIndex(); 
            }
          }}
        />
        <div className="">
          <WobbleBubble left="70%" top={180 * 0.2} colorClass={PLUM} width={200} animated />
          <WobbleBubble left="45%" top="20%" colorClass={BROWN} width={120} animated />
          <WobbleBubble left="40%" top="15%" colorClass={PUMPKIN} width={100} animated />
          <WobbleBubble left={-300 * 0.3} top={50} colorClass={PETROLEUM} width={300} animated />
          <WobbleBubble left="70%" top="45%" colorClass={PEA} width={140} animated />
          <WobbleBubble left="80%" top="35%" colorClass={PUMPKIN} width={100} animated />
          <WobbleBubble left="3%" top="70%" colorClass={PEA} width={200} animated />
          <WobbleBubble left="25%" top="65%" colorClass={PLUM} width={100} animated />
          <WobbleBubble left="45%" top="58%" colorClass={PEA} width={100} animated />
          <WobbleBubble left="50%" top="60%" colorClass={PETROLEUM} width={120} animated />
          <WobbleBubble left={window.innerWidth - 0.8 * 200} top={window.innerHeight - 155} colorClass={BROWN} width={200} animated />
        </div>
      </div>
    );
  }
});

const Cursor = forwardRef(({ className = '', fixed, onClick = () => {}, size = SIZE }, ref) => {
  return (
    <div
      ref={ref}
      className={`md:fixed cursor-pointer md:cursor-none flex justify-center items-center rounded-full bg-black text-white font-bold uppercase z-20 ${className}`}
      style={{
        width: size,
        height: size,
      }}
      onClick={onClick} 
      tabIndex="0" 
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick(); 
        }
      }}
    >
      explore
    </div>
  );
});

const FooterWrapper = ({ children }) => {
  return (
    <div className="relative w-full bg-whiteSmoke z-30">
      {children}
    </div>
  );
};
