import React from 'react'
import { useNavigate } from 'react-router-dom'

import { MENU_WIDTH2 } from '../lib/constants'

// import { ReactComponent as Logo } from '..//assets/home_icon.svg'
import { ReactComponent as Logo } from '..//assets/UNDP_logo_black.svg'
import { ReactComponent as MenuIcon } from '../assets/menu.svg'



export function VerticalIndexMenu2() {
  const navigate = useNavigate()

  return (
    <div
      // z index should be bigger than all the subsection tabs
      className=" top-0 left-0 fixed h-full pt-4 pb-6 bg-whiteSmoke z-10  flex flex-col flex-wrap justify-between"
      style={{ width: MENU_WIDTH2 }}
    >
      <Logo className="w-8 ml-4 cursor-pointer" onClick={() => navigate('/')} />
      <div className="flex flex-col ml-3">
   

      <MenuIcon className="w-6 ml-2 cursor-pointer" onClick={() => navigate('/index')} />
        
      </div>

      
    </div>
  )
}
