import { observer } from 'mobx-react-lite'
import React from 'react'
export const ElevationSummerystatus = observer(() => {


  return (
    <div className="     ">
   <div style={{ borderTop: '1px solid lightgray', marginLeft:-10 ,marginBottom:-12}}>   </div> 
        <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 15 }}>

 <table className=' border-b    flex-1 ' style={{marginRight: 0,maxWidth:3000,minHeight:40}}>
 <tr >
 <th ></th>
 <th></th>
 <th></th>
 <th></th>
 </tr>

 
 <tr>


 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1">Total area   </tr>
 <tr class="custom-Geography2">923,768  km<sup>2</sup> (356,376 sq m<sup>2</sup>)  </tr>
 <tr style={{ fontSize: 12 ,fontWeight: 500,padding:3,color: '#FFFFFF'}}> .</tr>
 </td>
 

 <td style={{ minWidth:0 }}>
 <tr class="custom-Geography1"> Coastline </tr>
 <tr class="custom-Geography2"> 853 km <t style={{ fontSize: 12 ,fontWeight: 500,padding:3,color: '#FFFFFF'}}> ..................</t></tr>
 <tr style={{ fontSize: 12 ,fontWeight: 500,padding:3,color: '#FFFFFF'}}> .</tr>
 </td>

 <td style={{ minWidth:0 }}>
  <tr class="custom-Geography1"> Highest elevation</tr>
 <tr class="custom-Geography2"> Kaduna | 644.30m</tr>
 <tr class="custom-Geography2">Plateau | 589.35m</tr>
 </td>
 
 
 <td style={{ minWidth:0 }}>
 <tr style={{ fontSize: 11,textTransform: 'uppercase' ,fontWeight: 900,padding:3,color: '#966084'}}> Lowest elevation</tr>
 <tr class="custom-Geography2">Lagos | 10.97m</tr>
 <tr class="custom-Geography2"> Bayelsa | 14.19m</tr>
 </td>


 
 </tr>
 
 </table>
 <div  className='flex-1  text-greyDark' style={{ fontSize: 10 ,minHeight:41,fontWeight:500,marginTop: 3  }}>
Source: NASA. 2020. Shuttle Radar Topography Mission (SRTM). Available <a class="custom-link" href=" https://www2.jpl.nasa.gov/srtm/" target="_blank" >  here</a>

 
      
</div>
 </div>
 
 
      
 
      </div>

      


  )

})
