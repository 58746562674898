import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { Map } from '../chartContainers/Map'
import { RegistrationToVoteChart } from '../smallCharts/RegistrationToVoteChart'

export const RegistrationToVoteViz = observer(() => {
  const {
    data: { registrationToVoteByLikeliness, registrations },
    filter: { selectedRegistration, setSelectedRegistration },
  } = useMst()

  return (
    <ChartContent
      isMapChart={true}
      Filters={() => {
        return (
          <div className="">
            <div className="" style={{ color: 'rgba(0, 0, 0, 1)' }}>
              Registration displayed
            </div>
            {registrations.map((registration) => {
              const isSelected = selectedRegistration === registration
              return (
                <div
                  key={registration}
                  className={`flex items-center cursor-pointer ${isSelected ? 'font-bold' : ''}`}
                  onClick={() => setSelectedRegistration(registration)}
                >
                  <div
                    className={`bg-black ${isSelected ? 'w-6 mr-1' : 'w-0'}`}
                    style={{ height: 1 }}
                  />
                  <div className="capitalize">{registration}</div>
                </div>
              )
            })}
          </div>
        )
      }}
      chart={
        <Map
          dataset={registrationToVoteByLikeliness(selectedRegistration)}
          borderColor="#B2978B"
          MapTileChart={RegistrationToVoteChart}
        />
      }
      Legend={() => null}
    />
  )
})
