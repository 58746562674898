import React from 'react'

export const Top5LegendChart = () => {
  return (
<>
    <div ></div>
    <svg
      width="162"
      height="82"
      viewBox="0 0 162 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_472_98476)">
        <path d="M81 1H1V81H81V1Z" fill="white" />
        <path d="M33.5 1H1V32H33.5V1Z" fill="#DAA367" stroke="white" />
        <path d="M33.5 32H1V61H33.5V32Z" fill="#DAA367" stroke="white" />
        <path d="M33.5 61H1V81H33.5V61Z" fill="#DAA367" stroke="white" />
        <path d="M60.5 1H33.5V21.5H60.5V1Z" fill="#DAA367" stroke="white" />
        <path d="M81 1H60.5V21.5H81V1Z" fill="#DAA367" stroke="white" />
        <path d="M81 21.5H33.5V81H81V21.5Z" fill="#DAA367" stroke="white" />
      </g>
      <g clipPath="url(#clip1_472_98476)">
        <path d="M161 1H81V81H161V1Z" fill="white" />
        <path d="M133 1H81V53.5H133V1Z" fill="#F5C48E" stroke="white" />
        <path d="M133 53.5H81V81H133V53.5Z" fill="#F5C48E" stroke="white" />
        <path d="M147 1H133V43.5H147V1Z" fill="#F5C48E" stroke="white" />
        <path d="M161 1H147V43.5H161V1Z" fill="#F5C48E" stroke="white" />
        <path d="M139.5 43.5H133V81H139.5V43.5Z" fill="#F5C48E" stroke="white" />
        <path d="M161 43.5H139.5V81H161V43.5Z" fill="#F5C48E" stroke="white" />
      </g>
      <rect x="1" y="1" width="160" height="80" stroke="#F2B572" />
      <path d="M81 1L81 81" stroke="#F2B572" />
      <defs>
        <clipPath id="clip0_472_98476">
          <rect width="80" height="80" fill="white" transform="translate(1 1)" />
        </clipPath>
        <clipPath id="clip1_472_98476">
          <rect width="80" height="80" fill="white" transform="translate(81 1)" />
        </clipPath>
      </defs>
    </svg>
    </>
  )
}
