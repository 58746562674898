import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { stateInstance, Provider } from '../state/index.js'

import { Landing } from './Landing'
import { Login } from './Login'
import { Index } from './Index.js'
import { Index2 } from './Index2.js'
import { Section } from './Section.js'
import { Verso } from './Verso.js'
import { dataset } from '../dataset/dataset.jsx'
import { Annex } from './Annex.js'
import { Annex2 } from './Annex2.js'

export class App extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isLoggedin: true // here
  //   }
  // }

  // onLoginSucess = (isLoginSuccess) => {
  //   this.setState({ isLoggedin: isLoginSuccess })
  // }

  render() {
    return (
      <div>
        {/* {this.state.isLoggedin ? */}
          <Provider value={stateInstance}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="index" element={<Index />} />
                <Route path="index2" element={<Index2 />} />
                <Route path="annex" element={<Annex />} />
                <Route path="annex2" element={<Annex2 />} />
                


                {dataset.map((section) => (
                  <Route
                    key={section.id}
                    path={section.url}
                    element={<Section key={section.id} section={section} activeSubsection={0} />}
                  />
                ))}

                <Route path="verso" element={<Verso />} />
                <Route
                  path="*"
                  element={
                    <main style={{ padding: '1rem' }}>
                      <p>Invalid URL: try again</p>
                    </main>
                  }
                />
              </Routes>
            </BrowserRouter>
          </Provider>
          
          
          {/* // :
          // <Login onLoginSucess={this.onLoginSucess} />
        } */
        
        
        }
      </div>
    )
  }
}
