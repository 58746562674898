import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { Map } from '../chartContainers/Map'
import { PieChart } from '../smallCharts/PieChart'

export const DailyPowerViz = observer(() => {
    const {
        data: { dailyPower, dailyPowerByActivity },
        filter: { selectedActivity, },
    } = useMst()

    return (
        <div style={{ paddingTop: 20}}>
        <ChartContent
            isMapChart={true}
            chart={
                <Map
                    dataset={dailyPowerByActivity(selectedActivity)}
                    borderColor="transparent"
                    chartFill="#309992"
                    MapTileChart={PieChart}
                />
            }
            Legend={() => null}
        />
        </div>
    )
})
