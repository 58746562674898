import React from 'react'
import ReactDOM from 'react-dom'
import '@fortawesome/fontawesome-free/css/all.min.css';
// import './fonts.css'; // Import the font definitions
import { App } from './components/App'
import './main.css'

function renderApp() {
  ReactDOM.render(
    <App />,
    document.getElementById('root')
  )
}

// First render
renderApp()
