import html2canvas from 'html2canvas';

export async function downloadElementToImage(snapshotElem, fileName = 'UrbanisationChart.png') {
    const snapshotCanvas = await html2canvas(snapshotElem, {
        dpi: 300,
        height: snapshotElem?.offsetHeight,
        width: snapshotElem?.offsetWidth,
        logging: false,
        scale: 5,
    });
    const imageUrl = await snapshotCanvas.toDataURL();
    const link = document.createElement('a');
    link.download = fileName;
    link.href = imageUrl;
    link.click();
    console.log(`${fileName} downloaded!`);
}