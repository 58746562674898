import React from 'react'
import { max } from 'lodash-es'
import { scaleSqrt, scaleLinear } from 'd3-scale'

import roadDensity from '../../dataset/roadDensity.json'
import { TEXT_SHADOW } from '../../lib/styleUtils'

export const DensityChart = ({ datum, size }) => {
  const densityScale = scaleSqrt()
    .domain([0, max(roadDensity.map((d) => d.density))])
    .range([0, size - 5])

  const lengthScale = scaleLinear()
    .domain([0, max(roadDensity.map((d) => d.length))])
    .range([6, size - 5])

  const width = densityScale(datum.density)
  return (
    <>
      <svg x={0} y={0} width={size} height={size}>
        {datum.density && (
          <rect
            x={0}
            y={size - width}
            width={width}
            height={width}
            fill="rgba(171, 128, 158, 0.7)"
          />
        )}
        {datum.length && (
          <rect
            x={0}
            y={size - lengthScale(datum.length)}
            width={size}
            height="1"
            fill="rgba(171, 128, 158, 1)"
          />
        )}
      </svg>

      {datum.length && (
        <div
          className="absolute left-1 top-5 text-greyDark text-xs"
          style={{ textShadow: TEXT_SHADOW }}
        >
          {Math.round(datum.length)}
        </div>
      )}

      {datum.density && (
        <div
          className="absolute left-1 bottom-0.5 text-black text-sm font-bold"
          style={{ textShadow: TEXT_SHADOW }}
        >
          {Math.round(datum.density)}
        </div>
      )}
    </>
  )
}
