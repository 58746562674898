import React from 'react'
import { TEXT_SHADOW } from '../../lib/styleUtils'

export const MapTile = ({
  x,
  y,
  size,
  id,
  hoveredTileId,
  isInteractive,
  dataset,
  datum,
  MapTileChart = () => null,
  borderColor,
  fillColor,
  colorScale,
  semicircleLabels,
  latestYear,
  oldestYear,
  chartFill,
  barLabels,
  isMobile,
}) => {
  return (
    <div
      className={!isMobile ? 'absolute' : 'relative'}
      style={{
        boxSizing: 'content-box',
        left: x,
        top: y,
        backgroundColor: fillColor,
        borderColor: borderColor,
        borderWidth: hoveredTileId === id && isInteractive ? 2 : 1,
        width: size,
        height: size,
      }}
      id="map-tile"
    >
      <MapTileChart
        dataset={dataset}
        datum={datum}
        size={size}
        colorScale={colorScale}
        semicircleLabels={semicircleLabels}
        latestYear={latestYear}
        oldestYear={oldestYear}
        chartFill={chartFill}
        barLabels={barLabels}
      />

      <div
        className="absolute text-greyDark top-1 left-1 text-xs"
        style={{ textShadow: TEXT_SHADOW }}
      >
        {id}
      </div>
    </div>
  )
}
