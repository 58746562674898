import React, { useState } from 'react'
import { downloadElementToImage } from '../utils/utils';

import { SquareTile } from '../components/chartContainers/SquareTile'
import { WhatNigeriansAskChart } from '../components/smallCharts/WhatNigeriansAskChart'
// import DownloadIcon from '@mui/icons-material/Download';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';
import { SQUARE_TILE_SIZE } from '../lib/constants'
const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const WhatNigeriansAskLegend = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const mockDatum = {
    keyword: 'Tinubu',
    trendValue: 81.08,
  }
  const handleCaptureDownoadClick = () => {
    const sectionComponent = document.getElementById("subsection");
    const snapshotElem = sectionComponent.getElementsByClassName("chartcontent-component")?.[0];
    if (snapshotElem) {
      setIsDownloading(true);
      setTimeout(async () => {
        await downloadElementToImage(snapshotElem,"what-nigeria-ask.png");
        setIsDownloading(false);
      }, 0)
    }
  }
  return (
    
    <div className="ml-10 flex flex-col gap-y-2">
    <div className="font-bold">How to read it</div>
      <div className="flex gap-x-1 relative ml-10">
        <SquareTile
          SquareTileChart={WhatNigeriansAskChart}
          datum={mockDatum}
          size={SQUARE_TILE_SIZE}
          borderColor="#E4E4E4"
        />
        <div>
          <div className="text-black text-sm font-bold pt-2">Trend value</div>
          <div className="text-black text-sm font-bold pt-24">Search Keyword</div>
        </div>

        <div className="absolute top-0 text-xs text-greyDark" style={{ right: '102%' }}>
          100
        </div>
        <div className="absolute bottom-8 text-xs text-greyDark" style={{ right: '102%' }}>
          0
        </div>
      </div>
      <div style={{ display: 'flex',  alignItems: 'left' ,marginTop:15,marginLeft:-10}}>
      <button type="button" onClick={handleCaptureDownoadClick} style={{ color: 'rgb(9, 126, 118)' }}disabled={isDownloading} className="flex items-center">
          {isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
          {!isDownloading && (
            <DownloadIcon
              className="ml-2"
              style={{  width: '26px', height: '26px', stroke: 'rgb(9, 126, 118)', strokeWidth: '2' }} 
            />
          )}
        </button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
    </div>
  )
}
