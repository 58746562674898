import React from 'react'
import { sortBy } from 'lodash-es'

import { STATES_CONFIG, INTERNAL_PADDING } from '../../lib/mapConstants'
import { MapTile } from './MapTile'
import '../../App.css'

export const MapMobile = ({
  tileSize,
  dataset,
  MapTileChart = () => null,
  fillColor,
  borderColor,
  colorScale,
  semicircleLabels,
  latestYear,
  oldestYear,
  chartFill,
  barLabels,
}) => {
  return (
    <div className="">
      <div
        className="map-component"
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fill, ${tileSize}px)`,
          gridGap: INTERNAL_PADDING,
        }}
        id='map-component'
      >
        {sortBy(STATES_CONFIG, 'id').map((state, i) => {
          const datum = dataset.find((el) => el.stateCode === state.id)
          return (
            <MapTile
              isMobile={true}
              key={i}
              size={tileSize}
              id={state.id}
              dataset={dataset}
              datum={datum}
              MapTileChart={MapTileChart}
              borderColor={borderColor}
              fillColor={fillColor}
              colorScale={colorScale}
              semicircleLabels={semicircleLabels}
              latestYear={latestYear}
              oldestYear={oldestYear}
              chartFill={chartFill}
              barLabels={barLabels}
            />
          )
        })}
      </div>
    </div>
  )
}
