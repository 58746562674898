import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { Map } from '../chartContainers/Map'
import { StackedBarchart } from '../smallCharts/StackedBarchart'

import { LIVING_STANDARDS_COLOR_SCALE } from '../../lib/constants'

export const ChangeLivingStandardsViz = observer(() => {
  const {
    data: { changeLivingStandards },
  } = useMst()

  return (
    <ChartContent
      isMapChart={true}
      chart={
        <Map
          dataset={changeLivingStandards}
          borderColor="#B2978B"
          colorScale={LIVING_STANDARDS_COLOR_SCALE}
          MapTileChart={StackedBarchart}
        />
      }
      Legend={() => null}
    />
  )
})
