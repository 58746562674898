import React from 'react'

export const ImportExportLegendChart = () => {
  return (
    <svg
      width="162"
      height="82"
      viewBox="0 0 162 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="39" y="39" width="42.05" height="42.05" fill="#DAA367" />
      <rect x="81.05" y="63.6699" width="17.38" height="17.38" fill="#F5C48E" />
      <rect x="1" y="1" width="160" height="80" stroke="#F2B572" />
      <path d="M81 1L81 81" stroke="#F2B572" />
    </svg>
  )
}
