import { observer } from 'mobx-react-lite'
import React from 'react'
export const PopulationSpatialSummerystatus = observer(() => {

  
  return (
    <div className=" ">
      
      
        <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 0 }}>

        
        <div  className='flex-1 text-greyDark' style={{borderTop: '1px solid lightgray', fontSize: 10 ,fontWeight:500,marginTop: 5,minHeight:50  }}>
<br/>Source: Schiavina M., Freire S., MacManus K. (2022) GHS-POP R2022A - GHS population grid multitemporal (1975-2030).European Commission, Joint Research Centre (JRC). Available <a class="custom-link" href="http://data.europa.eu/89h/d6d86a90-4351-4508-99c1-cb074b022c4a, doi:10.2905/D6D86A90-4351-4508-99C1-CB074B022C4A" target="_blank" > here</a> 

 
      
</div>
 </div>
 
 
      
 
      </div>

      


  )

})
