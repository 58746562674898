import React, { useState, useRef, useEffect } from 'react'
import {
  STATES_CONFIG,
  INTERNAL_PADDING,
  rowsCounter,
  columnsCounter,
  MAP_LARGE_TILE_SIZE_DESKTOP,
} from '../../lib/mapConstants'
import { MapTile } from './MapTile'
import { MapTooltip2 } from '../smallCharts/MapTooltip2'

export const MapDesktop2 = ({
  tileSize,
  dataset,
  isInteractive,
  MapTileChart = () => null,
  fillColor,
  borderColor,
  colorScale,
  semicircleLabels,
  latestYear,
  oldestYear,
  chartFill,
  stateArea,
  year,
  barLabels,
}) => {
  const [hoveredTileId, setHoveredTileId] = useState(null)
  const [leftSidefTheMapWidth, setLeftSidefTheMapWidth] = useState(null)
  const [bottomSidefTheMapWidth, setBottomSidefTheMapWidth] = useState(null)
  const tileSizeWithPadding = tileSize + INTERNAL_PADDING
  const chartContainerWidth = tileSizeWithPadding * columnsCounter
  const chartContainerHeight = tileSizeWithPadding * rowsCounter

  const mapContainerRef = useRef(null)

  useEffect(() => {
    setLeftSidefTheMapWidth(mapContainerRef.current.getBoundingClientRect().left)
    setBottomSidefTheMapWidth(mapContainerRef.current.getBoundingClientRect().bottom)
  }, [leftSidefTheMapWidth, bottomSidefTheMapWidth])

  return (

       <div className="w-full h-full flex items-center justify-center map2-component" id='map2-component'>
      <div
        ref={mapContainerRef}
        className="relative"
        style={{ width: chartContainerWidth, height: chartContainerHeight }}
      >
        {STATES_CONFIG.map((state) => {
          const { id, r, c, largeTile } = state
          const datum = dataset.find((el) => el.stateCode === id)
          const x = (c - 1) * tileSizeWithPadding
          const y = (r - 1) * tileSizeWithPadding

          return (
            <div
              key={id}
              onMouseEnter={() => {
                if (isInteractive) setHoveredTileId(id)
              }}
              onMouseLeave={() => {
                if (isInteractive) setHoveredTileId(null)
              }}
            >
              {largeTile ? (
                <MapTile
                  x={x}
                  y={y}
                  size={MAP_LARGE_TILE_SIZE_DESKTOP}
                  id={id}
                  hoveredTileId={hoveredTileId}
                  isInteractive={isInteractive}
                  dataset={dataset}
                  datum={datum}
                  MapTileChart={MapTileChart}
                  borderColor={borderColor}
                  fillColor={fillColor}
                  colorScale={colorScale}
                  semicircleLabels={semicircleLabels}
                  latestYear={latestYear}
                  oldestYear={oldestYear}
                  chartFill={chartFill}
                  barLabels={barLabels}
                />
              ) : (
                <MapTile
                  x={x}
                  y={y}
                  size={tileSize}
                  id={id}
                  hoveredTileId={hoveredTileId}
                  isInteractive={isInteractive}
                  dataset={dataset}
                  datum={datum}
                  MapTileChart={MapTileChart}
                  borderColor={borderColor}
                  fillColor={fillColor}
                  colorScale={colorScale}
                  semicircleLabels={semicircleLabels}
                  latestYear={latestYear}
                  oldestYear={oldestYear}
                  chartFill={chartFill}
                  barLabels={barLabels}
                />
              )}
              {hoveredTileId === id && isInteractive && (
                <MapTooltip2
                  x={x}
                  y={y}
                  size={tileSize}
                  datum={datum}
                  colorScale={colorScale}
                  area={stateArea}
                  year={year}
                  leftSidefTheMapWidth={leftSidefTheMapWidth}
                  bottomSidefTheMapWidth={bottomSidefTheMapWidth}
                />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
