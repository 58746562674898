import React from 'react'

export const StackedBarchart = ({ datum , size, colorScale


}) => {
  let heightSum = 0
  return (
    <>
      <svg x={0} y={0} width={size} height={size}>
        {colorScale.domain().map((label) => {
          heightSum += (datum[label]  * size) / 100
           
          const height = Math.max((datum[label] * size) / 100 - 0,0)
         
          return (
            <g key={label}>
              <rect
                x={0}
                y={size - heightSum} // size - previous rect heights
                width={size}
                height={height}
                fill={colorScale(label)}
                
              />
              

               
            </g>
            
          )
          return (datum)
        })}
      </svg>
      
    </>
  )
}
