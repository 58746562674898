import { max, min, pick } from 'lodash-es'

import { MONTHS } from './constants'

export function fromKeyToIndex(dataset, key) {
  return dataset.findIndex((d) => d.key === key)
}

export function maxDatumPerColumn(dataset, column) {
  return max(dataset.map((d) => d[column]))
}

export function maxMonthlyValue(dataset) {
  return max(dataset.flatMap((d) => Object.values(pick(d, MONTHS))))
}

export function minMonthlyValue(dataset) {
  return min(dataset.flatMap((d) => Object.values(pick(d, MONTHS))))
}
