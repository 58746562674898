import React from 'react'
import { arc, pie } from 'd3'

import { TEXT_SHADOW } from '../../lib/styleUtils'

export const ClockChart = ({ datum, size, chartFill }) => {
  const CLOCK_RADIUS = size / 2 - 5

  const angleGen = pie()
    .value((d) => d.pct)
    .sort(null)

  const circleData = angleGen([datum, { ...datum, pct: 1 - datum.pct }])

  function generateArc(radius, data, index) {
    const arcGen = arc()
      .innerRadius(0)
      .outerRadius(radius)
      .startAngle(data[index].startAngle)
      .endAngle(data[index].endAngle)

    return arcGen()
    
  }

  return (
  
    
    <>
      <svg x={0} y={0} width={size} height={size}>
        <g>
          <path
            d={generateArc(CLOCK_RADIUS, circleData, 0)}
            fill={chartFill}
            stroke={chartFill}
            strokeWidth={1}
            transform={`translate(${size / 2}, ${size / 2})`}
          />
          <path
            d={generateArc(CLOCK_RADIUS, circleData, 1)}
            fill="transparent"
            transform={`translate(${size / 2}, ${size / 2})`}
            stroke={chartFill}
            strokeWidth={1}
          />
        </g>
      </svg>

      <div
        className="absolute left-1 bottom-0.5 text-black text-sm font-bold"
        style={{ textShadow: TEXT_SHADOW }}
      >
        {datum.time}
      </div>
    </>
    
  )
}
