import { observer } from 'mobx-react-lite'
import React from 'react'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'

export const GrowthSpatialViz = observer(() => {
  const {
    filter: { selectedState },
  } = useMst()

  const images = {
    'Uyo, Akwa Ibom': 'Growth_Akwa Ibom',
    'Onitsha, Anambra': 'Growth_Anambra',
    'Abuja, FCT': 'Growth_Federal Capital Territory',
    'Kano, Kano': 'Growth_Kano',
    'Lagos metropolitan': 'Growth_Lagos',
    'Maiduguri, Borno': 'Growth_Maidugri',
    'Ibadan, Oyo': 'Growth_Oyo',
  }

  return (

    <div className="" style={{height: '90vh', paddingTop: 30}}>
    <ChartContent
      isMapChart={true}
      chart={
        <div className="flex justify-center">
        
          <img
          
            src={`spatial/${images[selectedState]}.jpg`}
            
            alt="cities growth raster"
            style={{ width: 800 }}
          />
        </div>
      }
    />
     </div>
  )
})
