import React from 'react'

import { ReactComponent as GradientScale } from '../assets/population.colors.svg'

export const PopulationSpatialLegend = () => {
  return (
    <div className="ml-10 flex flex-col gap-y-2">
       <div className="font-bold" >How to read it</div>
      <div className="text-sm text-greyDark">Population growth</div>
      <div className="relative" style={{ width: '100px' }}>
        <GradientScale width={100} />
        <div className="absolute top-5 text-sm text-greyDark">Low</div>
        <div className="absolute top-5 text-sm text-greyDark" style={{ left: 80 }}>
          High
        </div>
      </div>
    </div>
  )
}
