import { observer } from 'mobx-react-lite'

import { useMst } from '../state'
import { fromNigeriaToNigeriaLegend } from '../lib/legendUtils'

export const FromNigeriaToNigeriaLegend = observer(() => {
  const {
    filter: { importExportSelectedDataset },
  } = useMst()

  return fromNigeriaToNigeriaLegend[importExportSelectedDataset]
  
})
