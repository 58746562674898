import { observer } from 'mobx-react-lite'
import React from 'react'
export const ReligiousCentersSummerystatus = observer(() => {

  
  return (
    <div className=" ">
      
      
      <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 0 }}>

<table className=' border-b    flex-1 ' style={{  marginRight: 0,maxWidth:3000 ,minHeight:40 }}>
<tr >
<th ></th>
<th></th>
<th></th>
<th></th>
</tr>


<tr>
<td style={{ minWidth:0 }}>
<tr style={{ fontSize: 11.5 ,minWidth:400,fontWeight: 900,padding:3,color:'#AF9A5B' }}> States with the.... </tr>

</td>





<td style={{ minWidth:0 }}>
 <tr style={{ fontSize: 11,textTransform: 'uppercase',fontWeight: 900,padding:3 ,color:'#AF9A5B'}}>highest # of churches </tr>
<tr style={{ fontSize: 12 ,fontWeight: 500,padding:3}}>Benue (3,790) </tr>
<tr style={{ fontSize: 12 ,fontWeight: 500,padding:3}}>Akwa-Ibom (3,706) </tr>
<tr style={{ fontSize: 12 ,fontWeight: 500,padding:3}}> Ebonyi (2,459)</tr>
</td>

<td style={{ minWidth:0 }}>
 <tr style={{ fontSize: 11,textTransform: 'uppercase',fontWeight: 900,padding:3 ,color:'#AF9A5B'}}>highest # of mosques </tr>
<tr style={{ fontSize: 12 ,fontWeight: 500,padding:3}}>Borno (4,304)  </tr>
<tr style={{ fontSize: 12 ,fontWeight: 500,padding:3}}>Gombe (3,135)  </tr>
<tr style={{ fontSize: 12 ,fontWeight: 500,padding:3}}> Bauchi (2,511) </tr>
</td>

<td style={{ minWidth:0 }}>
 <tr style={{ fontSize: 11,textTransform: 'uppercase',fontWeight: 900,padding:3 ,color:'#AF9A5B'}}>highest # of churches per capita  </tr>
<tr style={{ fontSize: 12 ,fontWeight: 500,padding:3}}>Ebonyi (7.55)   </tr>
<tr style={{ fontSize: 12 ,fontWeight: 500,padding:3}}>Akwa-Ibom (7.44)  </tr>
<tr style={{ fontSize: 12 ,fontWeight: 500,padding:3}}> Benue (6.17)  </tr>
</td>

<td style={{ minWidth:0 }}>
 <tr style={{ fontSize: 11,textTransform: 'uppercase',fontWeight: 900,padding:3 ,color:'#AF9A5B'}}>highest # of mosques per capita   </tr>
<tr style={{ fontSize: 12 ,fontWeight: 500,padding:3}}>Gombe (7.92)   </tr>
<tr style={{ fontSize: 12 ,fontWeight: 500,padding:3}}>Borno (7.04)   </tr>
<tr style={{ fontSize: 12 ,fontWeight: 500,padding:3}}> Nasarawa (3.17) </tr>
</td>

</tr>

</table>
<div  className='flex-1 text-greyDark' style={{ fontSize: 10 ,minHeight:10,fontWeight:500,marginTop: 4  }}>
Source: GRID3. 2020. Building used for religious activities particularly for rituals and worship services. Available <a class="custom-link" href="https://grid3.gov.ng/datasets?&sector=[%22religion%22]" target="_blank" > here</a> 

     
</div>
</div>


     

     </div>

     


 )

})

