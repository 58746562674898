import React, { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'
import { ChartContent } from './ChartContent'
import { SquaresGrid } from '../smallCharts/SquaresGrid'
import { SquaresGrid2 } from '../smallCharts/SquaresGrid2'
import { SquaresGrid3 } from '../smallCharts/SquaresGrid3'
import { SquaresGrid4 } from '../smallCharts/SquaresGrid4'
import { SquaresGrid5 } from '../smallCharts/SquaresGrid5'
import { SquaresGrid6 } from '../smallCharts/SquaresGrid6'
import { SquaresGrid7 } from '../smallCharts/SquaresGrid7'
import { SquaresGrid8 } from '../smallCharts/SquaresGrid8'
import { SquaresGrid9 } from '../smallCharts/SquaresGrid9'
import { SquaresGrid10 } from '../smallCharts/SquaresGrid10'

export const SignificantIssuesViz = observer(() => {
    const {
        data: { findData },
    } = useMst()

    const containerRef = useRef(null)

    return (
        <div id="SignificantIssuesVizContainer" ref={containerRef} className="" style={{ marginBottom: '0vh' }}>
          <div  className= 'abc-component grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-3 mx-20 pb-10 gap-y-4'
             style={{
                transform: 'translateY(-20%)',
                height: '10vh',paddingTop:45
            }}
        >
            <div className='flex flex-col col-span-3 xl:col-span-1 '>
                <div style={{ fontWeight: 800 ,fontSize:15 }}>
                    Total
                </div>
                <SquaresGrid heading='&nbsp;' dataObj={findData('total', 'total')} />
            </div>
            <div className='xl:col-span-2 flex flex-col'>
                <div style={{ fontWeight: 800 ,fontSize:15 }}>
                    Gender
                </div>
                <div className='flex-1 ' style={{ borderTop: '1px solid black', marginRight: 5 }}></div>
                <div className='grid grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 'style={{ fontSize:14 }}>
                    <SquaresGrid heading='Male' dataObj={findData('gender', 'male')} />
                    <SquaresGrid2 heading='Female' dataObj={findData('gender', 'female')} />
                </div>
            </div>
            <div className='col-span-3 flex flex-col gap-y-0.9'>
                <div style={{ fontWeight: 800 ,fontSize:15 }}>
                    Age Group
                </div>
                <div className='flex-1' style={{ borderTop: '1px solid black', marginRight: 5 }}></div>
                <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 'style={{ fontSize:14 }}>
                    <SquaresGrid3 heading='18-34' dataObj={findData('age', '18-34')} />
                    <SquaresGrid heading='35-60' dataObj={findData('age', '35-60')} />
                    <SquaresGrid4 heading='61 years +' dataObj={findData('age', '61 years +')} />
                </div>
            </div>
            <div className='col-span-3 flex flex-col'>
                <div style={{ fontWeight: 800 ,fontSize:15 }}>
                    Geopolitical Zone
                </div>
                <div className='flex-1' style={{ borderTop: '1px solid black', marginRight: 5 }}></div>
                <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 'style={{ fontSize:14 }}>
                    <SquaresGrid5 heading='North Central' dataObj={findData('zone', 'North Central')} />
                    <SquaresGrid6 heading='North East' dataObj={findData('zone', 'North East')} />
                    <SquaresGrid7 heading='North West' dataObj={findData('zone', 'North West')} />
                    <SquaresGrid8 heading='South East' dataObj={findData('zone', 'South East')} />
                    <SquaresGrid9 heading='South South' dataObj={findData('zone', 'South South')} />
                    <SquaresGrid10 heading='South West' dataObj={findData('zone', 'South West')} />
                    
                </div>
            </div>
            <br/><br/><br/><br/>
        </div>
        
        </div>
        
    )
})
