import React, { useState } from 'react'
import { downloadElementToImage } from '../utils/utils';
import { MapTile } from '../components/chartContainers/MapTile'
import { SemicircleChart } from '../components/smallCharts/SemicircleChart'
import DownloadIcon from '@mui/icons-material/Download';
import { MAP_TILE_SIZE_DESKTOP } from '../lib/mapConstants'
import HealthCentersDataset from '../dataset/HealthCentersDataset.json'

const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const SchoolsHealthCentersLegend3 = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const mockDatum = {
    stateCode: 'LA',
    stateName: 'Lagos',
    "public":	0.56,
    "private":	1.08,
    
  }
  const handleCaptureDownoadClick = () => {
    const sectionComponent = document.getElementById("subsection");
    const snapshotElem = sectionComponent.getElementsByClassName("chartcontent-component")?.[0];
    if (snapshotElem) {
      setIsDownloading(true);
      setTimeout(async () => {
        await downloadElementToImage(snapshotElem,"SchoolsHealthCenters.png");
        setIsDownloading(false);
      }, 0)
    }
  }
  return (
    <div className="ml-0 flex flex-col gap-y-2">
       <div className="font-bold">How to read it</div>
      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
        <div className="absolute">
          <MapTile
            borderColor="transparent"
            size={MAP_TILE_SIZE_DESKTOP}
            fillColor="#F5F4F4"
            MapTileChart={() => (
              <div className="absolute text-greyDark top-1 left-1 text-xs">LA</div>
            )}
          />
          <div
            className="absolute top-0 text-sm text-greyDark whitespace-nowrap font-medium"
            style={{ left: 90 }}
          >
            State code
          </div>
        </div>
      </div>
     
      <div className="relative flex gap-x-3," style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
        <div
          className="absolute"
          style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
        >
          <MapTile
            borderColor="transparent"
            size={MAP_TILE_SIZE_DESKTOP}
            dataset={HealthCentersDataset}
            datum={mockDatum}
            fillColor="#F5F4F4"
            MapTileChart={SemicircleChart}
            semicircleLabels={['public']}
          />
        </div>
        
        <div
          className="absolute text-sm text-greyDark font-semibold"
          style={{ left: MAP_TILE_SIZE_DESKTOP + 15, width: 180 }}
        >
          Number of Public Schools per 10k school-age population
       {/*   <div className="font-normal">Min:0.5 (BO)</div>
          <div className="font-normal">Max:2.9 (EB)</div>
          */}
        </div>
      </div>

            
      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
        <div
          className="absolute"
          style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
        >
          <MapTile
            borderColor="transparent"
            size={MAP_TILE_SIZE_DESKTOP}
            dataset={HealthCentersDataset}
            datum={mockDatum}
            fillColor="#F5F4F4"
            MapTileChart={SemicircleChart}
            semicircleLabels={[null, 'private']}
          />
        </div>

        <div
          className="absolute text-sm text-greyDark font-semibold"
          style={{ left: MAP_TILE_SIZE_DESKTOP + 15, width: 182 }}
        >
          Number of Private Schools per 10k school-age population
       {/*   <div className="font-normal">Min: 0.4 (RI)</div>
          <div className="font-normal">Max: 3.5 (CR)</div>
          */}
        </div>
      </div>
      <div style={{ display: 'flex',  alignItems: 'left',marginTop:20 ,marginLeft:-10}}>
        <button type="button" onClick={handleCaptureDownoadClick} disabled={isDownloading}style={{ color: 'rgb(171, 148, 78)' }} >
        {isDownloading ? 'Downloading...' : <span className="font-bold text-black">DOWNLOAD </span> }
          < DownloadIcon className="ml-2" />
          
        </button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
    </div>
  )
}
