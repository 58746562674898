import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useMst } from '../../state';

const FILTER_DATA = {
    agrDgr: [
        'Generally speaking most people I interact with can be trusted',
        'In general, men make better political leaders than women',
        'Gay and lesbian people should be free to live their own lives',
        'I feel safe walking alone in my neighborhood after dark',
    ],
};

export const AgrDgrFilter = observer(({ chartType = 'agrDgr' }) => {
    const {
        filter: { horizontalChartSelected, setHorizontalChartSelected },
    } = useMst();

    const [filterData, setFilterData] = useState(FILTER_DATA.agrDgr);

    useEffect(() => {
        switch (chartType) {
            case 'agrDgr':
                setFilterData(FILTER_DATA.agrDgr);
                break;
            default:
                break;
        }
        setHorizontalChartSelected(FILTER_DATA[chartType][0]);
    }, [chartType]);

    const handleKeyDown = (event, dataType) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            setHorizontalChartSelected(dataType);
        }
    };

    return (
        <div className="flex flex-col">
            {filterData.map((dataType) => {
                const isSelected = horizontalChartSelected === dataType;
                return (
                    <div
                        key={dataType}
                        className={`flex items-center cursor-pointer activity-item ${isSelected ? 'selected font-bold' : ''}`}
                        onClick={() => setHorizontalChartSelected(dataType)}
                        onKeyDown={(event) => handleKeyDown(event, dataType)}
                        tabIndex={0}
                        style={{
                            width: 320,
                            paddingBottom: 10,
                            color: isSelected ? '#8D796E' : '#000000',
                            backgroundColor: isSelected ? '#e2ddda' : 'transparent',
                            padding: '5px',
                        }}
                        aria-label={`Filter by ${dataType}`}
                    >
                        <div className="" style={{ textOverflow: 'ellipsis', fontSize: 20 }}>{dataType}</div>
                    </div>
                );
            })}
        </div>
    );
});
