import React, { useState } from 'react'
import { downloadElementToImage } from '../utils/utils';

// import DownloadIcon from '@mui/icons-material/Download';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';

import { MapTile } from '../components/chartContainers/MapTile'

import { MAP_TILE_SIZE_DESKTOP } from '../lib/mapConstants'
import { PRECIPITATIONS_BAR_COLOR_SCALE, PRECIPITATIONS_COLOR_SCALE } from '../lib/constants'
import { TemperaturesChartcopy } from '../components/smallCharts/TemperatureChartcopy'

const legendLabels = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jul",
  "jun",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec"
].reverse()
const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const TemperaturesLegend = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const mockDatumHDI = {
    stateCode: 'LA',
    stateName: 'Lagos',
    HDI: 0.686,
    quarter_1: 10.659,
    quarter_2: 11.724,
    quarter_3: 8.676,
    quarter_4: 5.3,

      "jan":28.15,
      "feb":30.22,
      "mar":31.24,
      "apr":30.06,
      "may":28.2,
      "jun":26.02,
      "jul":25.15,
      "aug":24.7,
      "sep":25.2,
      "oct":26.19,
      "nov":27.76,
      "dec":28.04,
  
    
  }

  const handleCaptureDownoadClick = () => {
    const sectionComponent = document.getElementById("subsection");
    const snapshotElem = sectionComponent.getElementsByClassName("map-component")?.[0];
    if (snapshotElem) {
      setIsDownloading(true);
      setTimeout(async () => {
        await downloadElementToImage(snapshotElem,"Temperatures.png");
        setIsDownloading(false);
      }, 0)
    }
  }

  return (
    <div className="ml-10 flex flex-col gap-y-2">
         <div className="font-bold">How to read it</div>
      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
        <div className="absolute">
          <MapTile
            size={MAP_TILE_SIZE_DESKTOP}
            borderColor="#AA809E"
            MapTileChart={() => (
              <div className="absolute text-greyDark top-1 left-1 text-xs">LA</div>
            )}
          />
          <div
            className="absolute top-0 text-sm text-greyDark whitespace-nowrap font-medium"
            style={{ left: 90 }}
          >
            State code
          </div>
        </div>
      </div>
      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
        <div className="absolute">
          <MapTile
            size={MAP_TILE_SIZE_DESKTOP}
            borderColor="#AA809E"
            MapTileChart={() => (
              <>
                <div className="absolute text-greyDark top-1 right-1 text-xs">+27.38</div>
                <div className="absolute top-4 right-1 text-xs" style={{ color: '#A880A1' }}>0.50</div>
              </>
            )}
          />
          <div
            className="absolute top-0 text-sm text-greyDark whitespace-nowrap font-medium"
            style={{ left: 90 }}
          >
         Ave. temperature (°C)  
            <div style={{ color: '#A880A1' }}> Δ between ave. <br />2000-2020 and 2021-2022(°C) <br /></div>
          </div>
        </div>
      </div>

      <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
        <div
          className="absolute"
          style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
        >
          <div className='relative'>
            <MapTile
              size={MAP_TILE_SIZE_DESKTOP}
              datum={mockDatumHDI}
              borderColor="#AA809E"
              MapTileChart={TemperaturesChartcopy}
              colorScale={PRECIPITATIONS_BAR_COLOR_SCALE}
            />
            <div style={{ position: 'absolute', top: MAP_TILE_SIZE_DESKTOP + 3, fontSize:9 }}>
              Jan Apr Jul Oct
            </div>
          </div>

          <div className="absolute top-0 text-xs text-greyDark" style={{ right: '105%' }}>
            45
          </div>
          <div className="absolute bottom-0 text-xs text-greyDark" style={{ right: '105%' }}>
            0
          </div>
        </div>

        <div
          className="absolute text-sm text-greyDark"
          style={{ left: MAP_TILE_SIZE_DESKTOP + 15 }}
        >
          Monthly Avg temperatures(°C)
        </div>
      </div>
      <div style={{ display: 'flex',  alignItems: 'left',marginTop:25,marginLeft:-10 }}>
      <button type="button" onClick={handleCaptureDownoadClick} disabled={isDownloading} className="flex items-center">
          {isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
          {!isDownloading && (
            <DownloadIcon
              className="ml-2"
              style={{  width: '26px', height: '26px', stroke: '#946886', strokeWidth: '2' }} 
            />
          )}
        </button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
    </div>
  )
}
