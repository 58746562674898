import { observer } from 'mobx-react-lite'
import React from 'react'
export const SchoolsHealthCentersSummerystatus = observer(() => {

  
  return (
    <div className=" "style={{ marginTop: 0 }}>
      <div style={{ borderTop: '1px solid lightgray',marginLeft:-10 ,marginBottom:0}}>   </div> 
      
      <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop: 0 }}>

<table className=' border-b    flex-1 ' style={{  marginRight: 0,maxWidth:3000 ,minHeight:0 }}>
<tr >
<th ></th>
<th></th>
<th></th>
<th></th>
</tr>


<tr>
<td style={{ minWidth:0 }}>
 <tr style={{ fontSize: 11,textTransform: 'uppercase',minWidth:400,fontWeight: 900,padding:3,color:'#AF9A5B' }}> States with the.... </tr>

</td>



<td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1">highest # of health facilities per capita  </tr>
<tr class="custom-Demographics2">Osun 3.31 </tr>
<tr class="custom-Demographics2">Cross River 3.23  </tr>
<tr class="custom-Demographics2">Nassarawa 3.23   </tr>
</td>


<td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1">lowest # of health facilities per capita  </tr>
<tr class="custom-Demographics2">Rivers 0.52  </tr>
<tr class="custom-Demographics2">Yobe 0.67   </tr>
<tr class="custom-Demographics2">Zamfara 0.70  </tr>
</td>


<td style={{ minWidth:0 }}>
 <tr style={{ fontSize: 11,textTransform: 'uppercase',fontWeight: 900,padding:3 ,color:'#AF9A5B'}}>highest # of schools per capita </tr>
<tr class="custom-Demographics2">Ebonyi 28 </tr>
<tr class="custom-Demographics2">Nasarawa 22  </tr>
<tr class="custom-Demographics2">Abia 22   </tr>
</td>

<td style={{ minWidth:0 }}>
 <tr class="custom-Demographics1">lowest # of schools per capita  </tr>
<tr class="custom-Demographics2">Borno 5   </tr>
<tr class="custom-Demographics2">Yobe 7   </tr>
<tr class="custom-Demographics2">Sokoto 8  </tr>


</td>


</tr>

</table>
<div  className='flex-1 text-greyDark' style={{ fontSize: 10 ,minHeight:0,fontWeight:500,marginTop: 3 }}>
Source:Schools/GRID3. 2020. Designated institution to provide learning spaces and learning environments for the teaching of students.Available <a class="custom-link" href="https://grid3.gov.ng/datasets?q=schools" target="_blank" >here </a> 
   . Health facilities /GRID3. 2020. Entities that provide medical and/or healthcare services and/or engage in the use generally of natural and/or artificial materials to create or dispense drugs; hospitals, pharmacy, clinic, health post dispensary.Available <a class="custom-link" href="https://grid3.gov.ng/datasets?&sector=[%22health-and-safety%22" target="_blank" >here</a> 
     
</div>
</div>


     

     </div>

     


 )

})
