import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'
import { ChartContent } from './ChartContent'
import { Map } from '../chartContainers/Map'
import { SemicircleChart } from '../smallCharts/SemicircleChart'

export const HealthCentersPerCapitaViz  = observer(() => {
  const {

    data: { HealthCentersPerCapita },
  } = useMst()

  return (
    <div style={{ height: '90vh',paddingTop: 20 }}>
    <ChartContent
      isMapChart={true}
      chart={
        <Map
          dataset={HealthCentersPerCapita}
          isInteractive={false}
          borderColor="transparent"
          fillColor="#F5F4F4"
          semicircleLabels={['public', 'private']}
          MapTileChart={SemicircleChart}
        />
      }
    />
    </div>
  )
})


