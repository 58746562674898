import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { Map2 } from '../chartContainers/Map2'
import { StackedBarchart } from '../smallCharts/StackedBarchart'

import { LAND_USE_COLOR_SCALE } from '../../lib/constants'
import { ReactCompareSlider, ReactCompareSliderHandle } from 'react-compare-slider';
import "./custom-slider.css"
import { LandUseSpatialViz } from './LandUseSpatialViz'

const LandUseByYear = ({ year }) => {
  const {
    data: { landUseByYear },
  } = useMst()

  return (
    <>
      {year === 1975? (
        <div className='flex justify-between'>
          <div>
            1975
          </div>
          <div style={{ width: 100, backgroundColor: 'white' }}>
          </div>
        </div>
      ) : (
        <div className='flex justify-end'>
         2020
        </div>
      )}
      <ChartContent
        isMapChart={true}
        chart={
          <Map2
            dataset={landUseByYear(year)}
            isInteractive={true}
            borderColor="#AB809E"
            colorScale={LAND_USE_COLOR_SCALE}
            MapTileChart={StackedBarchart}
            stateArea={true}
          />
        }
      />
    </>
  )
}

export const LandUseViz = observer(() => {
  const {
    filter: { landUseSelected },
  } = useMst()

  return (
    <div className="" style={{ height: '94vh', paddingTop: 10}}>
      
      {landUseSelected === 'Land Use %' ? (
        <ReactCompareSlider
          handle={<ReactCompareSliderHandle style={{ color: '#8e577d' }} />}
          itemOne={<LandUseByYear year={1975} />}
          itemTwo={<LandUseByYear year={2020} />}
        />
      ) : (
        <LandUseSpatialViz />
      )}
      
    </div>
    
   
  )
})
