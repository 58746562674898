import React, { useEffect,useState } from 'react'
import { downloadElementToImage } from '../utils/utils';
import { MapTile } from '../components/chartContainers/MapTile'
import { TreeChart } from '../components/smallCharts/TreeChart'
import { MAP_TILE_SIZE_DESKTOP } from '../lib/mapConstants'
import { ConcernChart } from '../components/smallCharts/ConcernChart'
import { useMst } from '../state'
import { observer } from 'mobx-react-lite'
const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';
// import DownloadIcon from '@mui/icons-material/Download';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';
const CHART_COMMENTS = {
    concern: ' Percent extremely/very concerned',
    satisfaction: 'Percent satisfied with the government\'s performance',
    towards2023: 'Percent likely to vote in the 2023 General Elections'
}

export const ConcernsLegend = observer(({ chartType = 'concern' }) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const {
        data: { findConcernData },
        filter: { concernSelected }
    } = useMst()

    const [chartComments, setchartComments] = useState(CHART_COMMENTS.concern)

    useEffect(() => {
        console.log(concernSelected);
    }, [concernSelected])
    useEffect(() => {
        switch (chartType) {
            case 'concern':
                setchartComments(CHART_COMMENTS.concern)
                break;
            case 'satisfaction':
                setchartComments(CHART_COMMENTS.satisfaction)
                break;
            case 'towards2023':
                setchartComments(CHART_COMMENTS.towards2023)
                break;
            default:
                break;
        }
    }, [chartType])



    const handleCaptureDownoadClick = () => {
        const sectionComponent = document.getElementById("subsection");
        const snapshotElem = sectionComponent.getElementsByClassName("concern-component")?.[0];
        if (snapshotElem) {
          setIsDownloading(true);
          setTimeout(async () => {
            await downloadElementToImage(snapshotElem,"image.png");
            setIsDownloading(false);
          }, 0)
        }
      }
    return (
        <div className="flex flex-col gap-y-2">
            <div className="font-bold">How to read it</div>
            <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP }}>
                Male
                <div
                    className="absolute"
                    style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
                >
                    <TreeChart size={80} datum={findConcernData(chartType, concernSelected, "total", "").data} heading="&nbsp;" />
                </div>
                <div
                    className="absolute text-sm text-greyDark font-semibold"
                    style={{ left: MAP_TILE_SIZE_DESKTOP + 15, width: 125 }}
                >Subpopulation
                </div>
            </div>
            <div className="relative flex gap-x-3" style={{ minHeight: MAP_TILE_SIZE_DESKTOP,top: 10 }}>
                <div
                    className="absolute"
                    style={{ minHeight: MAP_TILE_SIZE_DESKTOP, minWidth: MAP_TILE_SIZE_DESKTOP }}
                >
                    <ConcernChart size={80} datum={findConcernData(chartType, concernSelected, "total", "total").data} heading="&nbsp;" />
                </div>

                <div
                    className="absolute text-sm text-greyDark font-semibold"
                    style={{ left: MAP_TILE_SIZE_DESKTOP + 15, top: 28, width: 125 }}
                >
                    {concernSelected === 'Interest in politics' ? "Percent interested in politics" : chartComments}
                </div>
                </div>
                <br/>
                <br/>
            <div  style={{ display: 'flex',  alignItems: 'left' , marginTop:15,marginLeft:-10}}>
       
               
            <button type="button" onClick={handleCaptureDownoadClick} style={{ color: 'rgb(157, 124, 109)' }}disabled={isDownloading} className="flex items-center">
{isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
{!isDownloading && (
  <DownloadIcon
    className="ml-2"
    style={{  width: '26px', height: '26px', stroke: 'rgb(157, 124, 109)', strokeWidth: '2' }} 
  />
)}
</button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
        </div>
    )
}
)