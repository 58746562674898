import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { Map } from '../chartContainers/Map'
import { ClockChart } from '../smallCharts/ClockChart'

export const DayInTheLifeViz = observer(() => {
  const {
    data: { aDayInTheLifeByActivity, },
    filter: { selectedActivity, },
  } = useMst()

  return (
    <div style={{ paddingTop: 20}}>
    <ChartContent
      isMapChart={true}
      chart={
        <Map
          dataset={aDayInTheLifeByActivity(selectedActivity)}
          borderColor="transparent"
          chartFill="#309992"
          MapTileChart={ClockChart}
        />
      }
      Legend={() => null}
    />
    </div>
  )
  
})
