import React, { useState } from 'react';
import { downloadElementToImage } from '../utils/utils';
import { observer } from 'mobx-react-lite';
import { ReactComponent as DownloadIcon } from '../assets/download-icon.svg';
import { MapTile } from '../components/chartContainers/MapTile';
import { StackedBarchart } from '../components/smallCharts/StackedBarchart';
import { LAND_USE_COLOR_SCALE } from '../lib/constants';
import { useMst } from '../state';

const loadingIcon = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

export const LandUseLegend = observer(() => {
  const [isDownloading, setIsDownloading] = useState(false);

  const {
    filter: { landUseSelected }
  } = useMst();

  const mockDatum = {
    stateCode: "LA",
    stateName: "Lagos",
    geoZone: "South West",
    year: 1975,
    "Water/wetland": 25.64,
    "Built Up": 14.48,
    "Cropland/vegetation": 13.6,
    "Forest": 23.62,
    "Open Land/Shrubs": 21.88,
    stateArea: "3,671.48"
  };

  const handleCaptureDownoadClick = () => {
    const sectionComponent = document.getElementById("subsection");
    const snapshotElem = sectionComponent.getElementsByClassName("map2-component")?.[0];
    if (snapshotElem) {
      setIsDownloading(true);
      setTimeout(async () => {
        await downloadElementToImage(snapshotElem, "landuse.png");
        setIsDownloading(false);
      }, 0);
    }
  };

  return (
    <div className="flex flex-col gap-y-2">
      <div className="font-bold">How to read it</div>
      {landUseSelected === 'Land Use %' && (
        <>
          <div className="relative flex gap-x-3" style={{ minHeight: 75 }}>
            <div className="absolute">
              <MapTile
                size={75}
                borderColor="#AB809E"
                MapTileChart={() => (
                  <div className="absolute text-greyDark top-1 left-1 text-xs">LA</div>
                )}
              />
              <div
                className="absolute top-0 text-sm text-greyDark whitespace-nowrap font-medium"
                style={{ left: 90 }}
              >
                State code
              </div>
            </div>
          </div>

          <div className="relative flex gap-x-3" style={{ minHeight: 75 }}>
            <div className="absolute" style={{ minHeight: 75, minWidth: 75 }}>
              <MapTile
                size={75}
                datum={mockDatum}
                colorScale={LAND_USE_COLOR_SCALE}
                borderColor="#AB809E"
                MapTileChart={StackedBarchart}
              />

              <div className="absolute top-0 text-xs text-greyDark" style={{ right: '102%' }}>
                100%
              </div>
              <div className="absolute bottom-0 text-xs text-greyDark" style={{ right: '102%' }}>
                0%
              </div>
            </div>
            <div className="absolute text-sm text-greyDark font-medium" style={{ left: 90 }}>
              Land Use Categories (%)
            </div>
          </div>
        </>
      )}

      <div className="flex flex-col gap-y-1">
        {LAND_USE_COLOR_SCALE.domain()
          .reverse()
          .map((label) => (
            <div className="legend-element flex justify-between" key={label}>
              <div className="flex gap-x-1">
                <div
                  className="square w-4 h-4"
                  style={{ background: LAND_USE_COLOR_SCALE(label) }}
                />
                <div className="label text-sm">{label}</div>
              </div>
            </div>
          ))}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 15, marginLeft: -10 }}>
        <button type="button" onClick={handleCaptureDownoadClick} disabled={isDownloading} className="flex items-center">
          {isDownloading ? 'Downloading...' : <span className=" text-black"style={{  fontSize:16,fontWeight:700,letterSpacing:0.5 }} >DOWNLOAD</span>}
          {!isDownloading && (
            <DownloadIcon
              className="ml-2"
              style={{  width: '26px', height: '26px', stroke: '#946886', strokeWidth: '2' }} 
            />
          )}
        </button>
        {isDownloading && <img width={24} src={loadingIcon} alt='' />}
      </div>
    </div>
  );
});
