import React from 'react';
import FooterImage from '../assets/UNDP_logo_White.svg';
import facebookIcon from '../assets/facebook-social-media.svg';
import XIcon from '../assets/x-social-media.svg';
import instagramIcon from '../assets/instagram-social-media.svg';
import linkedinIcon from '../assets/linkedin-social-media.svg';
import youtubeIcon from '../assets/youtube-social-media.svg';

export const Footer = () => {
  return (
    <footer className="text-white text-center py-14 mt-auto w-full" style={{ backgroundColor: '#0468B1', fontFamily: 'ProximaNova' }}>
      <div className="flex flex-row justify-between items-center px-4 md:px-24">
        <div className="flex flex-row items-center mb-2 md:mb-0 -ml-6 md:-ml-8">
          <a href="https://www.undp.org" target="_blank" rel="noopener noreferrer">
            <img src={FooterImage} alt="Footer Logo" className="h-24 md:h-32 mt-1" />
          </a>

          <div className="responsive-text1 text-left -ml-3 mt-5 -space-y-1">
            <div>
              United Nations
            </div>
            <div>
              Development Programme
            </div>
          </div>
        </div>
        <div className="hidden md:flex flex-col items-end space-y-8">
          <div className="flex flex-col items-end space-y-4" style={{ fontSize: '16px' }}>
            <a href="https://data.undp.org/" className="text-white" target="_blank" rel="noopener noreferrer">
              Data Futures Exchange
            </a>
            <a href="https://www.undp.org/copyright-terms-use" className="text-white" target="_blank" rel="noopener noreferrer">
              Terms Of Use
            </a>
          </div>
          <div className="flex space-x-4 md:space-x-6 text-xl md:text-2xl">
            <a href="https://www.facebook.com/undpafricaofficial/" className="text-white" target="_blank" rel="noopener noreferrer">
              <img src={facebookIcon} alt="facebook" className="h-6 md:h-5 mt-1.5" />
            </a>
            <a href="https://www.linkedin.com/company/undp/" className="text-white" target="_blank" rel="noopener noreferrer">
              <img src={linkedinIcon} alt="linkedin" className="h-6 md:h-5 mt-1" />
            </a>
            <a href="https://www.instagram.com/undpafrica/" className="text-white" target="_blank" rel="noopener noreferrer">
              <img src={instagramIcon} alt="instagram" className="h-7 md:h-6 mt-1" />
            </a>
            <a href="https://x.com/UNDPAfrica" className="text-white" target="_blank" rel="noopener noreferrer">
              <img src={XIcon} alt="X" className="h-4 md:h-4 mt-2" />
            </a>
            <a href="https://www.youtube.com/channel/UCORDtUtSkmv3CMrffA2wuSg" className="text-white" target="_blank" rel="noopener noreferrer">
              <img src={youtubeIcon} alt="youtube" className="h-7 md:h-5 mt-2" />
            </a>
          </div>
        </div>
      </div>

      <div className="border-t border-white mt-8 md:mt-14 mx-4 md:mx-24"></div>

      {/* Copyright and Developed by Section */}
      <div className="flex flex-row justify-between items-center px-4 mt-8 md:mt-8 mx-4 md:mx-20">
        <div className="responsive-text2 text-left mb-4 md:mb-0">
          © 2024 United Nations Development Programme
        </div>
        <div className="text-right mb-4 md:mb-0" style={{ fontSize: '12px', color: '#b0c4de' }}>
          Developed by 
          UNDP Nigeria 
          Knowledge Hub
        </div>
      </div>

      {/* Mobile-specific content placed after the copyright section */}
      <div className="block md:hidden flex flex-col items-start space-y-4 -mt-3.5 px-4 mx-4">
        <div className="flex flex-col items-start space-y-0" style={{ fontSize: '15px' }}>
          <a href="https://data.undp.org/" className="text-white" target="_blank" rel="noopener noreferrer">
            Data Futures Exchange
          </a>
          <a href="https://www.undp.org/copyright-terms-use" className="text-white" target="_blank" rel="noopener noreferrer">
            Terms Of Use
          </a>
        </div>
        <div className="flex space-x-6 text-xl mx-10">
          <a href="https://www.facebook.com/undpafricaofficial/" className="text-white" target="_blank" rel="noopener noreferrer">
            <img src={facebookIcon} alt="facebook" className="h-5 mt-1.5" />
          </a>
          <a href="https://www.linkedin.com/company/undp/" className="text-white" target="_blank" rel="noopener noreferrer">
            <img src={linkedinIcon} alt="linkedin" className="h-5 mt-1" />
          </a>
          <a href="https://www.instagram.com/undpafrica/" className="text-white" target="_blank" rel="noopener noreferrer">
            <img src={instagramIcon} alt="instagram" className="h-6 mt-1" />
          </a>
          <a href="https://x.com/UNDPAfrica" className="text-white" target="_blank" rel="noopener noreferrer">
            <img src={XIcon} alt="X" className="h-4 mt-2" />
          </a>
          <a href="https://www.youtube.com/channel/UCORDtUtSkmv3CMrffA2wuSg" className="text-white" target="_blank" rel="noopener noreferrer">
            <img src={youtubeIcon} alt="youtube" className="h-5 mt-1.5" />
          </a>
        </div>
      </div>
    </footer>
  );
};
