import { observer } from 'mobx-react-lite'
import React from 'react'
export const ImportExportSummerystatus = observer(() => {

  
  return (
    <div className=" "style={{ marginTop: 0 }}>
      <div style={{ borderTop: '1px solid lightgray',marginLeft:-10 ,marginBottom:0}}>   </div> 
      
        <div className=' xl:col-span-2 flex flex-col  top-0 'style={{ marginRight: 0,marginTop:0 }}>

 <table className=' border-b    flex-1 ' style={{  marginRight: 0,maxWidth:3000,marginTop: 0  ,minHeight:68}}>


 
 <tr>

 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Economy1"> Top 5 products exported</tr>
 
 <tr class="custom-Economy2"><t style={{fontWeight: 900}} > Mineral fuels:</t> 39.72B,<t style={{fontWeight: 900}} >Machinery and equipment:</t> 1.63.</tr>
 <tr class="custom-Economy2"><t style={{fontWeight: 900}} >Machinery and equipment:</t> 1.63,<t style={{fontWeight: 900}} >Chemical and fertilizer products:</t> 1.09.</tr>
 <tr class="custom-Economy2"><t style={{fontWeight: 900}} >Agriculture products: 1.48.</t></tr>
 </td>

 <td style={{ minWidth:0 }}>
 <tr class="custom-Economy1"> Top 5 products imported</tr>
 <tr class="custom-Economy2"> <t style={{fontWeight: 900}} >Mineral fuels:</t> 16.10B,<t style={{fontWeight: 900}} >Machinery and equipment:</t> 12.00.</tr>
 <tr class="custom-Economy2"> <t style={{fontWeight: 900}} >Machinery and equipment:</t> 12.00,<t style={{fontWeight: 900}} >Vehicles:</t> 3.44.</tr>
 <tr class="custom-Economy2"> <t style={{fontWeight: 900}} >Agriculture products:</t> 5.58.</tr></td>
 

 <td style={{ minWidth:0 }}>
 <tr class="custom-Economy1"> Top 5 Export partner</tr>
 <tr class="custom-Economy2"> <t style={{fontWeight: 900}} >India:</t> 7.73,<t style={{fontWeight: 900}} >Spain:</t> 5.58.</tr>
 <tr class="custom-Economy2"> <t style={{fontWeight: 900}} >Spain :</t> 5.58,<t style={{fontWeight: 900}} >Netherlands:</t> 2.84.</tr>
 <tr class="custom-Economy2"><t style={{fontWeight: 900}} >France:</t> 3.03.</tr>
 </td>
 


 
 
 <td style={{ minWidth:0 }}>
 <tr class="custom-Economy1"> Top 5 import partner</tr>
 <tr class="custom-Economy2"> <t style={{fontWeight: 900}} >China:</t> 12.90B,<t style={{fontWeight: 900}} >Netherlands:</t> 5.35.</tr>
 <tr class="custom-Economy2"> <t style={{fontWeight: 900}} >Netherlands :</t> 5.35,<t style={{fontWeight: 900}} >Belgium:</t> 3.78.</tr>
 <tr class="custom-Economy2"> <t style={{fontWeight: 900}} >India:</t> 4.60B.</tr>
 
 </td>

 </tr>
 
 </table>
 <div  className='flex-1 text-greyDark' style={{ fontSize: 10 ,minHeight:29,fontWeight:500,marginTop: 3}}>
Source: United Nations Statistics Division. 2021. UN Comtrade database. Available <a class="custom-link" href="https://comtrade.un.org/" target="_blank" > here</a>  [Accessed March 2023]

 
      
</div>
 </div>
 
 
      
 
      </div>

      


  )

})
