import React from 'react'
import { scaleSqrt } from 'd3-scale'

import { TEXT_SHADOW } from '../../lib/styleUtils'

export const ImportExportChart = ({ datum, size, label, fill, isAlignedRight, maxValue }) => {
  const areaScale = scaleSqrt()
    .domain([0, maxValue])
    .range([0, size - 5])

  const width = areaScale(datum[label])

  return (
    <>
      <svg x={0} y={0} width={size} height={size}>
        <rect
          x={isAlignedRight ? size - width : 0}
          y={size - width}
          width={width}
          height={width}
          fill={fill}
        />
      </svg>

      <div
        className="absolute text-sm top-0.5"
        style={{
          textShadow: TEXT_SHADOW,
          left: isAlignedRight ? 2 : 'unset',
          right: isAlignedRight ? 'unset' : 2,
        }}
      >
        {isAlignedRight ? datum.importLabel : datum.exportLabel}
      </div>
    </>
  )
}
