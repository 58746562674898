import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { VerticalIndexMenu2 } from './VerticalIndexMenu2'


export function Annex2 () {
  const [hoveredSection, setHoveredItemSection] = useState(null)
  const navigate = useNavigate()
     
  
  return (

   
<div className="    flex items-center justify-center p-16 flex-col grid grid-cols-1  xl:grid-cols-63  pt-1 pb-145 gap-y-4" >
<VerticalIndexMenu2 /> 

<div className='  xl:col-span-2 flex flex-col absolute top-0'style={{ marginRight: 0,marginTop: 17 }}>



<table className='  flex-1 ' style={{  marginRight: 50,maxWidth:2000 ,borderTop: '3px solid black'  }}>
		<tr >
    <div style={{ fontWeight: 900 }}> Annex</div>
		<th ></th>
		<th></th>
		<th></th>
    <th></th>
		</tr>
     
     
    
		<tr className=" bg-purple" >
<td style={{ fontSize: 13 }}><div style={{ fontWeight: 900 }}> Geography & Climate</div></td>
    
<td style={{ fontSize: 11 }}>
  


<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight: 900 }}> Elevation</td>
<td > NASA. 2020. Shuttle Radar Topography Mission (SRTM). Available at:<a style={{color: 'darkblue'}} href=" https://www2.jpl.nasa.gov/srtm/" target="_blank" >  https://www2.jpl.nasa.gov/srtm/</a> 
</td>
</tr>
<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900}}>Land Use</td>
<td>1975 - 2013: Tappan, G. G., Cushing, W.M., Cotillon, S.E., Mathis, M.L., Hutchinson, J.A., Herrmann, S.M., and Dalsted, K.J., 2016, West Africa Land Use Land Cover Time Series: U.S. Geological Survey data release. Available at: http://dx.doi.org/10.5066/F73N21JF
2020: Karra, Kontgis, et al. “Global land use/land cover with Sentinel-2 and deep learning.” IGARSS 2021-2021 IEEE International Geoscience and Remote Sensing Symposium. IEEE, 2021. Available at:<a style={{color: 'darkblue'}} href="https://www.arcgis.com/home/item.html?id=d6642f8a4f6d4685a24ae2dc0c73d4ac" target="_blank" > https://www.arcgis.com/home/item.html?id=d6642f8a4f6d4685a24ae2dc0c73d4ac</a> 
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Urbanisation Trend</td>
<td>1975 - 2013: Tappan, G. G., Cushing, W.M., Cotillon, S.E., Mathis, M.L., Hutchinson, J.A., Herrmann, S.M., and Dalsted, K.J., 2016, West Africa Land Use Land Cover Time Series: U.S. Geological Survey data release. Available at: http://dx.doi.org/10.5066/F73N21JF
2020: Karra, Kontgis, et al. “Global land use/land cover with Sentinel-2 and deep learning.” IGARSS 2021-2021 IEEE International Geoscience and Remote Sensing Symposium. IEEE, 2021. Available at: <a style={{color: 'darkblue'}} href="https://www.arcgis.com/home/item.html?id=d6642f8a4f6d4685a24ae2dc0c73d4ac" target="_blank" > https://www.arcgis.com/home/item.html?id=d6642f8a4f6d4685a24ae2dc0c73d4ac</a> 
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Growth of 6 selected cities</td>
<td>1975 - 2013: Tappan, G. G., Cushing, W.M., Cotillon, S.E., Mathis, M.L., Hutchinson, J.A., Herrmann, S.M., and Dalsted, K.J., 2016, West Africa Land Use Land Cover Time Series: U.S. Geological Survey data release. Available at: http://dx.doi.org/10.5066/F73N21JF
2020: Karra, Kontgis, et al. “Global land use/land cover with Sentinel-2 and deep learning.” IGARSS 2021-2021 IEEE International Geoscience and Remote Sensing Symposium. IEEE, 2021. Available at:<a style={{color: 'darkblue'}} href="https://www.arcgis.com/home/item.html?id=d6642f8a4f6d4685a24ae2dc0c73d4ac" target="_blank" > https://www.arcgis.com/home/item.html?id=d6642f8a4f6d4685a24ae2dc0c73d4ac</a> 
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Road density per state</td>
<td>Open street map OSM. 2020. OpenStreetMap data for Nigeria. Available at:<a style={{color: 'darkblue'}} href="https://download.geofabrik.de/africa/nigeria.htm" target="_blank" >https://download.geofabrik.de/africa/nigeria.htm</a>  
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Precipitation</td>
<td>National Aeronautics and Space Administration (NASA) Langley Research Center (LaRC) Prediction of Worldwide Energy Resource (POWER)Project funded through the NASA Earth Science/Applied Science Program: Monthly & Annual Precipitation, POWER Data Access Viewer v2.0.0,1981-2020. Available at: <a style={{color: 'darkblue'}} href="https://developers.google.com/earth-engine/datasets/catalog/MODIS_061_MOD11A2" target="_blank" > https://developers.google.com/earth-engine/datasets/catalog/MODIS_061_MOD11A2</a> 
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Temperature</td>
<td>National Aeronautics and Space Administration (NASA) Langley Research Center (LaRC) Prediction of Worldwide Energy Resource (POWER)Project funded through the NASA Earth Science/Applied Science Program: Monthly & Temperature, POWER Data Access Viewer v2.0.0,1981-2020. Available at:<a style={{color: 'darkblue'}} href=" https://developers.google.com/earth-engine/datasets/catalog/MODIS_061_MOD11A2" target="_blank" > https://developers.google.com/earth-engine/datasets/catalog/MODIS_061_MOD11A2</a>  
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Surface Temperature</td>
<td>Wan, Z., S. Hook, G. Hulley. MODIS/Terra Land Surface Temperature/Emissivity Daily L3 Global 1km SIN Grid V061. 2021, distributed by NASA EOSDIS Land Processes DAAC. Available at:<a style={{color: 'darkblue'}} href="https://doi.org/10.5067/MODIS/MOD11A1.061" target="_blank" >https://doi.org/10.5067/MODIS/MOD11A1.061</a>   . Accessed 2022-10-06.
</td>
</tr>

</td>  
    
 </tr>

		<tr className=" bg-orang" >
    <td style={{ fontSize: 13 }}><div style={{fontWeight:900 }}> Economy</div></td>
    
    <td style={{ fontSize: 11 }}>
  
  <tr>
  <td></td>
  <td > 
  </td>
  </tr>
  
  <tr>
  <td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Nigeria compared:<br/> GDP and GDP per capita</td>
  <td > International Monetary Fund. 2022. World Economic Outlook. Available at:<a style={{color: 'darkblue'}} href=" https://www.imf.org/en/Publications/WEO/weo-database/2022/October" target="_blank" > https://www.imf.org/en/Publications/WEO/weo-database/2022/October</a>   [Accessed 12 October 2021].
  </td>
  </tr>
  <br/>
  
  <tr>
  <td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Economic structure:<br/> Sector contribution to GDP</td>
  <td >National Bureau of Statistics. 2022. Nigerian Gross Domestic Product Report (Q2 2022). Available at:  <a style={{color: 'darkblue'}} href="https://nigerianstat.gov.ng/elibrary" target="_blank" > https://nigerianstat.gov.ng/elibrary</a>  [Accessed July 2022].
  </td>
  </tr>
  
  <br/>
  <tr>
  <td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>From Nigeria to Nigeria:<br/> Trade flows by partner <br/>and product</td>
  <td>United Nations Statistics Division. 2021. UN Comtrade database. Available at: <a style={{color: 'darkblue'}} href="https://comtrade.un.org/" target="_blank" > https://comtrade.un.org/</a>  [Accessed March 2023]
  </td>
  </tr>
  
 
  
  </td>  
      
   </tr>

    <tr className="bg-brown" >
    <td style={{ fontSize: 13 }}><div style={{ fontWeight:900 }}> Demographics</div></td>
    
    <td style={{ fontSize: 11 }}>
  
    <tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}> If Nigeria was 100 people</td>
<td > National Bureau of Statistics. 2020. Nigeria Living Standards Survey. Available at: <a style={{color: 'darkblue'}} href="https://nigerianstat.gov.ng/elibrary?queries=Nigeria%20Living%20Standards%20Survey" target="_blank" > https://nigerianstat.gov.ng/elibrary?queries=Nigeria%20Living%20Standards%20Survey</a>.United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey. 
</td>
</tr>
<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Where do Nigerians live:<br/> Population distribution<br/> and growth</td>
<td>WorldPop.2020. Population Counts. Available at:<a style={{color: 'darkblue'}} href="https://hub.worldpop.org/geodata/summary?id=49705" target="_blank" > https://hub.worldpop.org/geodata/summary?id=49705</a> 
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150 ,fontWeight:900}}>Raster of population growth</td>
<td>Schiavina M., Freire S., MacManus K. (2022) GHS-POP R2022A - GHS population grid multitemporal (1975-2030).European Commission, Joint Research Centre (JRC). Available at:<a style={{color: 'darkblue'}} href="http://data.europa.eu/89h/d6d86a90-4351-4508-99c1-cb074b022c4a, doi:10.2905/D6D86A90-4351-4508-99C1-CB074B022C4A" target="_blank" > http://data.europa.eu/89h/d6d86a90-4351-4508-99c1-cb074b022c4a, doi:10.2905/D6D86A90-4351-4508-99C1-CB074B022C4A</a> 
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Human Development Index</td>
<td>Global Data Lab. Subnational Human Development Index (2020). Nijmegen School of Management of Radboud University. Available at:<a style={{color: 'darkblue'}} href=" https://globaldatalab.org/areadata/table/shdi/NGA/" target="_blank" >  https://globaldatalab.org/areadata/table/shdi/NGA/</a> 
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Nigeria at night</td>
<td>Earth Observation Group, Payne Institute for Public Policy. C. D. Elvidge, M. Zhizhin, T. Ghosh, F-C. Hsu, "Annual time series of global VIIRS nighttime lights derived from monthly averages: 2012 to 2019", Remote Sensing (In press). Available at:<a style={{color: 'darkblue'}} href="https://www.mdpi.com/2072-4292/13/5/922" target="_blank" > https://www.mdpi.com/2072-4292/13/5/922</a>  .<a style={{color: 'darkblue'}} href="https://developers.google.com/earth-engine/datasets/catalog/NOAA_DMSP-OLS_NIGHTTIME_LIGHTS?hl=en" target="_blank" > https://developers.google.com/earth-engine/datasets/catalog/NOAA_DMSP-OLS_NIGHTTIME_LIGHTS?hl=en</a>  
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Schools per capita</td>
<td>GRID3. 2020. Designated institution to provide learning spaces and learning environments for the teaching of students. Available at:<a style={{color: 'darkblue'}} href=" https://grid3.gov.ng/datasets?q=schools" target="_blank" >  https://grid3.gov.ng/datasets?q=schools</a>  
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Health facilities per capita</td>
<td>GRID3. 2020. Entities that provide medical and/or healthcare services and/or engage in the use generally of natural and/or artificial materials to create or dispense drugs; hospitals, pharmacy, clinic, health post dispensary. Available at:<a style={{color: 'darkblue'}} href="https://grid3.gov.ng/datasets?&sector=[%22health-and-safety%22]" target="_blank" > https://grid3.gov.ng/datasets?&sector=[%22health-and-safety%22]</a>  
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Religious centres per <br/>capita</td>
<td>GRID3. 2020. Building used for religious activities particularly for rituals and worship services. Available at:<a style={{color: 'darkblue'}} href="https://grid3.gov.ng/datasets?&sector=[%22religion%22]" target="_blank" > https://grid3.gov.ng/datasets?&sector=[%22religion%22]</a>  
</td>
</tr>



</td>  
    
 </tr>

		<tr className=" bg-blue" >
    <td style={{ fontSize: 13 }}><div style={{ fontWeight:900 }}>Life & work</div></td>
    
    <td style={{ fontSize: 11 }}>
  
 
  
  <tr>
  <td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>A day in the life of:<br/> Average time spent <br/>on main activities</td>
  <td > United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey. 
  </td>
  </tr>
  <br/>
  
  <tr>
  <td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>At work</td>
  <td>United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey. 
  </td>
  </tr>
  
  <br/>
  <tr>
  <td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Getting around</td>
  <td>United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey.  
  </td>
  </tr>
  
  <br/>
  
  <tr>
  <td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Lights out</td>
  <td>United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey. 
  </td>
  </tr>

  <br/>
  
  <tr>
  <td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>na God o</td>
  <td>United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey. 
  </td>
  </tr>

  <br/>
  
  <tr>
  <td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>On the move</td>
  <td>United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey. 
  </td>
  </tr>

  <br/>
  
  <tr>
  <td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Strength from Abroad</td>
  <td>United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey. 
  </td>
  </tr>

  <br/>
  
  <tr>
  <td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Online</td>
  <td>United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey. 
  </td>
  </tr>

  <br/>
  
  <tr>
  <td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Hot topics</td>
  <td>United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey. 
  </td>
  </tr>


  </td>  
      
   </tr>
    <tr className=" bg-darkbrown" >
<td style={{ fontSize: 13 }}><div style={{ fontWeight:900 }}> Perceptions & Attitudes</div></td>
    
<td style={{ fontSize: 11 }}>
  
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}> Most Significant Issues <br/>Facing Nigeria</td>
<td > United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey. 
</td>
</tr>
<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Agree or disagree</td>
<td>United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey. 
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>How concerned are you <br/>about...</td>
<td>United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey. 
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Change in Financial <br/>Well-being</td>
<td>United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey. 
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Satisfaction in <br/>government's performance</td>
<td>United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey. 
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Towards 2023</td>
<td>United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey. 
</td>
</tr>

<br/>
<tr>
<td style={{ fontSize: 11 ,minWidth:150,fontWeight:900 }}>Wetin dey happen?</td>
<td>United Nations Development Programme, National Bureau of Statistics and Africa Polling Institute. 2022. Nigeria Social Survey. 
</td>
</tr>


</td>  
    
 </tr>

		
  
   
      
  
	</table>
	</div>
  </div>
     
 
)
   
     
 
}
