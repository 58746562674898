import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../../state'

import { ChartContent } from './ChartContent'
import { Verso } from '../Verso'

export const IfNigeriaWas100PeopleViz = observer(() => {
  const {
    data: { ifNigeriaWas100ByCategory },
    filter: { selected100PeopleCategory },
  } = useMst()

  return (
    <div className="verso_component"style={{paddingTop: 70,marginBottom:15 }}>
    <ChartContent
      chart={<Verso dataset={ifNigeriaWas100ByCategory(selected100PeopleCategory)} />}
      Legend={() => null}
    />
    </div>
  )
})
