import React from 'react'

import { MULTIPLE_SQUARES_SIZE } from '../../lib/constants'

export const MultipleSquaresTile = ({ datum, borderColor = '#309992', bgColor = { r: 30, g: 98, b: 94 } }) => {
  return (
    <>
      <span className="text-base leading-loose font-semibold">{datum[0].stateCurrent}</span>
      <div className="flex gap-2 flex-wrap">
        {datum.map((stateData) => {
          const bgOpacity = stateData.share / 100
          return (
            <div
              className="relative"
              key={stateData.stateCode}
              style={{
                backgroundColor: `rgba(${bgColor.r},${bgColor.g},${bgColor.b}, ${bgOpacity})`,
                borderColor: borderColor,
                borderWidth: 1,
                width: MULTIPLE_SQUARES_SIZE,
                height: MULTIPLE_SQUARES_SIZE,
              }}
            >
              <div
                className="absolute top-1 left-1 text-xs"
                style={{ color: bgOpacity <= 0.45 ? '#1E625E' : '#FFFFFF' }}
              >
                {stateData.stateCode}
              </div>

              <div
                className="absolute bottom-0.5 left-1 text-xs"
                style={{ color: bgOpacity <= 0.45 ? '#1E625E' : '#FFFFFF' }}
              >
                {stateData.share.toFixed(2)}%
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
