import { observer } from 'mobx-react-lite'
import React,{useState}from 'react'
//import './App.css'


 export function RoadDensityResource  ()  {
  
  const [isopen, setIsopen] = useState(false);

  return (
    <div className="">
      
      
      
        <div  className='flex-1 ' style={{ fontSize: 9 ,minWidth:150,fontWeight:800,marginRight: 0,maxWidth:2000  }}>
Source: Open street map OSM. 2020. OpenStreetMap data for Nigeria. Available at:<a style={{color: 'darkblue'}} href="https://download.geofabrik.de/africa/nigeria.htm" target="_blank" >https://download.geofabrik.de/africa/nigeria.htm</a>  
      
</div>
      

      
 


    
    </div>


  )
}