import React, { useState } from 'react'
export const TreeChart = ({ chartType, datum, size, borderColor = '#B2978B', heading = "", isInteractive = false }) => {
    let dissatisfiedWidth = size * size * datum.dissatisfied * 0.01 / size;
    let widthLeft = size - dissatisfiedWidth;

    let satisfiedHeight = size * size * datum.satisfied * 0.01 / widthLeft;
    let neitherHeight = size * size * datum.neither * 0.01 / widthLeft;

    const [tileHover, setTileHover] = useState(false)

    return (
        <div>
            <div style={{ paddingTop: 8 }}>
                {heading}
            </div>
            <div
                style={{ width: size, height: size, border: `1px solid ${borderColor}`, position: 'relative' }}
                onMouseEnter={() => {
                    setTileHover(true)
                }}
                onMouseLeave={() => {
                    setTileHover(false)
                }}
            >
                <div style={{ width: dissatisfiedWidth, height: size - 1.5, background: '#4a3527', bottom: -0.2, left: 0, top: -0.2, position: 'absolute' }}>
                </div>
                <div style={{ width: widthLeft, height: satisfiedHeight, background: '#8b6248', top: -0.2, right: -0.2, position: 'absolute' }}>
                </div>
                <div style={{ width: widthLeft, height: neitherHeight, background: '#b78f76', bottom: -0.2, right: -0.2, position: 'absolute' }}>
                </div>
                {/* <div style={{ position: 'absolute', bottom: 0, left: 2, color: 'white', fontSize: 12 }}>{datum}%</div> */}
                {isInteractive && tileHover && (
                    <div style={{ position: 'absolute', bottom: size / 2 - 10, right: size - 80, border: '1px solid #B2978B', background: 'white', width: 250, height: 120, zIndex: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ padding: 10 }}>
                                <div style={{ fontSize: 15, fontWeight: 1000, paddingBottom: 15 }}>
                                    {heading === "\u00A0" ? 'Total' : heading}
                                </div>
                                <div className="legend-element flex justify-between">
                                    <div className="flex gap-x-1">
                                        <div className="square w-4 h-4" style={{ background: '#4a3527' }} />
                                        <div className="label text-sm">{chartType === 'overallSatisfaction' ? 'Satisfied' : 'Improved'}</div>
                                    </div>
                                    <div className="percentage text-sm font-semibold">{datum.satisfied.toFixed(0)}%</div>
                                </div>
                                <div className="legend-element flex justify-between">
                                    <div className="flex gap-x-1">
                                        <div className="square w-4 h-4" style={{ background: '#8b6248' }} />
                                        <div className="label text-sm">{chartType === 'overallSatisfaction' ? 'Neither' : 'Remained the same'}</div>
                                    </div>
                                    <div className="percentage text-sm font-semibold">{datum.neither.toFixed(0)}%</div>
                                </div>
                                <div className="legend-element flex justify-between">
                                    <div className="flex gap-x-1">
                                        <div className="square w-4 h-4" style={{ background: '#b78f76' }} />
                                        <div className="label text-sm">{chartType === 'overallSatisfaction' ? 'Dissatisfied' : 'Getting Worse'}</div>
                                    </div>
                                    <div className="percentage text-sm font-semibold">{datum.dissatisfied.toFixed(0)}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
