import React from 'react'
import { scaleLinear } from 'd3-scale'

import { SingleBarBarchart } from './SingleBarBarchart'
import { TEXT_SHADOW } from '../../lib/styleUtils'

export const IntentionToVoteChart = ({ size, datum }) => {
  const scale = scaleLinear().domain([0, 100]).range([0, size])
  const height = scale(datum.likeliness)

  return (
    <>
      <SingleBarBarchart size={size} barHeight={height} barFill="#B2978B" />

      <div
        className="absolute right-1 top-1 text-black text-sm "
        style={{ textShadow: TEXT_SHADOW }}
      >
        {datum.likeliness}%
      </div>
    </>
  )
}
