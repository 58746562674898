import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { noop } from 'lodash-es'
import { ReactComponent as InfoIcon } from '../assets/UNDP_logo_black.svg'
import { ReactComponent as CloseIcon } from '../assets/close.svg'
import { fromKeyToIndex } from '../lib/dataUtils'
import { padWithZero } from '../lib/formatUtils'
import MenuMobile from './MenuMobile'
import { ReactComponent as Logo2 } from '../assets/annex.svg'
import { Footer } from './Footer';
import Header from './Header';
export const SectionMobile = observer(({ section, activeSubsection = 0 }) => {
  const { subsections } = section
  const defaultOpenSubsection = subsections[activeSubsection]

  const [openSubsectionId, setOpenSubsectionId] = useState(defaultOpenSubsection.key)
  const [isInfoModalOpen, setInfoModalOpen] = useState(false)

  const openSubsectionIndex = fromKeyToIndex(subsections, openSubsectionId)
  const openSubsection = subsections[openSubsectionIndex]
  const {
    Summerystatus = () => null,
    Summerystatus2 = () => null,
    Filter = () => null,
    Content = () => null,
    Legend = () => (
      <div className="flex flex-col gap-y-3">
        <div className="font-bold">  </div> <div>  </div>{' '}
      </div>
    ),
  } = openSubsection

  return (
    <div className="w-screen h-screen flex flex-col relative">
      {/* tabs */}{' '}
      <Header />
      <div className="flex">
        {' '}
        {subsections.map((subsection, i) => {
          const isActive = openSubsectionId === subsection.key
          const index = fromKeyToIndex(section.subsections, subsection.key) + 1
          const formattedIndex = padWithZero(index)

          return (

            
            <div
              key={subsection.key}
              className={`flex-1 flex justify-center items-center font-bold cursor-pointer hover:bg-white py-2 ${isActive ? 'bg-white' : 'bg-whiteSmoke'
                } transition-all`}
              style={{
                borderBottom: `1px solid ${isActive ? 'transparent' : 'black'}`,
                borderLeft: `${i === 0 ? 0 : 1}px solid black`,
              }}
              
              onClick={() => setOpenSubsectionId(subsection.key)}
            >
              
              {formattedIndex}{' '}
              
            </div>
            
          )
        })}{' '}
      </div>
     
      {/* content */}{' '}
      <div className="flex-1 bg-white overflow-y-auto" style={{ marginBottom: 5}}>
        <div className="px-4">
          <div className="flex flex-col gap-y-5 ">
            <div className={`font-bold pl-5 pr-1 py-4 select-none`}>
              {openSubsection.name}
              {openSubsection.description && (
                <span className="font-normal">{`: ${openSubsection.description}`}</span>
              )}
            </div>
            
            <Filter />
            <div className="font-bold"> </div>
            <Legend />
          </div>{' '}
        </div>
        <div style={{ paddingTop: 20 }}>
          <Content />
          <br/>
          <br/>
          <br/>
        </div>
        <div className="w-full bg-white overflow-y-hidden   flex flex-col gap-y-0" style={{ borderTop: '' ,paddingLeft:6,marginLeft:0,marginTop:1, paddingTop: 0}}>
        <Summerystatus />
      </div>

      <div className="w-full bg-white overflow-y-hidden   flex flex-col gap-y-0" style={{ borderTop: '' ,paddingLeft:6,marginLeft:0,marginTop:1, paddingTop: 0}}>
        <Summerystatus2 />

      </div>
 <div className=" bottom-0 w-full text-center" style={{ fontSize: 16 }}><Footer /></div>
      <MenuMobile />
      </div>
     
    </div>
  )
})
