import React from 'react'
import { observer } from 'mobx-react-lite'
import { useMst } from '../state'
import { SectionDesktop } from './SectionDesktop'
import { SectionMobile } from './SectionMobile'

export const Section = observer(({ section }) => {
  const {
    ui: { isDesktop },
  } = useMst()

  return isDesktop ? <SectionDesktop section={section} /> : <SectionMobile section={section} />
})
