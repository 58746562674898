import { padStart } from 'lodash-es'

export function padWithZero(value) {
  return padStart(value, 2, '0')
}

export function addPlusSign(datum, value, digits, unit) {
  const formattedDatum =
    datum[value] > 0 ? `+${datum[value].toFixed(digits)}` : datum[value].toFixed(digits)
  return `${formattedDatum}${unit}`
}
