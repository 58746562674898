import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoImage from '../assets/UNDP_logo.svg';
import { ReactComponent as Info } from '../assets/info-blue.svg';

const Header = () => {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1200);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsLargeScreen(window.innerWidth > 1440);
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const marginTopHeight = isMobile 
    ? isScrolled ? '0' : '0px'
    : isScrolled ? '0' : '6px';

  const headerHeight = isMobile ? '80px' : '115px'; // Smaller header height for mobile
  const logoHeight = isMobile
    ? isScrolled ? '80px' : '80px' // Smaller logo height for mobile
    : isScrolled ? '117.12px' : '122px';

  return (
    <header
      className="bg-gray10 p-1 sticky top-0 z-50 flex items-center w-full shadow-lg"
      style={{ height: headerHeight }}
    >
      <img
        src={LogoImage}
        alt="Logo"
        className="ml-2 mr-4 cursor-pointer transition-all duration-300"
        style={{
          height: logoHeight,
          marginTop: marginTopHeight,
          
        }}
        onClick={() => navigate('/')}
      />
      <div className="ml-2 flex flex-col">
        <h1
          className="text-sm text-gray-600 underline underline-thin underline-offset-small cursor-pointer"
          style={{
            color: '#55606e',
            fontSize: '0.75rem',
            textDecorationColor: '#aeb2ba',
            textUnderlineOffset: '4px',
            fontFamily: 'ProximaNova',
          }}
          onClick={() => navigate('/')}
        >
          DATA FUTURES EXCHANGE
        </h1>
        <p
          className="text-l cursor-pointer"
          style={{
            fontSize: isLargeScreen ? '1.25rem' : '1rem', // 1rem for mobile and normal desktop, 1.25rem for larger screens
            color: 'black',
            fontFamily: 'ProximaNova',
          }}
          onClick={() => navigate('/')} 
        >
          Nigeria Visualised
        </p>
      </div>
      <div className="flex-grow"></div>
      <Info className="h-5 w-8 mr-4 cursor-pointer" onClick={() => navigate('/annex')} />
    </header>
  );
};

export default Header;
