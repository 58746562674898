import { types as t, getRoot } from 'mobx-state-tree'
import { debounce } from 'lodash-es'

/*
'sm': '640px' // => @media (min-width: 640px) { ... }
'md': '768px' // => @media (min-width: 768px) { ... }
'lg': '1024px' // => @media (min-width: 1024px) { ... }
'xl': '1280px' // => @media (min-width: 1280px) { ... }
'2xl': '1536px' // => @media (min-width: 1536px) { ... }
*/
const MAP_MOBILE_THRESHOLD = 1050
const TABLET_THRESHOLD = 768

export const UIModel = t
  .model('UIModel', {
    isLoading: false,
    windowWidth: 0,
    windowHeight: 0,
  })
  .views((self) => ({
    get root() {
      return getRoot(self)
    },

    get isMobile() {
      return self.windowWidth < MAP_MOBILE_THRESHOLD
    },

    get isTablet() {
      return self.windowWidth > MAP_MOBILE_THRESHOLD && self.windowWidth < TABLET_THRESHOLD
    },

    get isDesktop() {
      return self.windowWidth > TABLET_THRESHOLD
    },
  }))
  .views((self) => ({}))
  .actions((self) => ({}))
  .actions((self) => ({
    computeWindowSize() {
      self.windowWidth = window.innerWidth
      self.windowHeight = window.innerHeight
    },
  }))
  .actions((self) => ({
    afterAttach() {
      self.computeWindowSize()
      window.addEventListener('resize', debounce(self.computeWindowSize, 50))
    },
  }))
